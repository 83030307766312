import './styles/darkstudiotemplate.webflow.5e2a8fa10.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import DashboardPage from "./pages/dashboard/index";
import ContactUsPage from "./pages/contact-us";
import AboutPage from "./pages/about";
import LandingLayout from "./layouts/LandingLayout";
import DashboardPage from "./pages/dashboard";
import PricingPage from "./pages/pricing";
import ProjectsLayout from "./layouts/ProjectsLayout";
import NotFoundPage from "./pages/not-found";
import ProjectsPage from "./pages/projects";
import SingleProjectPage from "./pages/projects/project";
import FormsPage from "./pages/forms";
import ComingSoonPage from "./pages/coming-soon";
import FeatureRequestPage from "./pages/client-forms/feature-request";
import IssueReportPage from "./pages/client-forms/issue-report";

function App() {
  return (
      <Router>
          <div>
              <Routes>
                  <Route path="/" element={<LandingLayout />}>
                      <Route index element={<DashboardPage />} />
                      <Route path="pricing" element={<PricingPage />} />
                      {/*<Route path="help-center" element={<HelpCenterPage />} />*/}
                      <Route path="about" element={<AboutPage />} />
                      <Route path="articles" element={<ComingSoonPage />} />
                      <Route path="contact" element={<ContactUsPage />} />
                      <Route path="forms" element={<FormsPage />} />
                  </Route>
                  <Route path="/projects/" element={<ProjectsLayout />}>
                      <Route index element={<ComingSoonPage />} />
                      <Route path=":id" element={<ComingSoonPage />} />
                  </Route>
                  <Route path="/clients/" element={<ProjectsLayout />}>
                      <Route path="feature-request" element={<FeatureRequestPage />} />
                      <Route path="report-issue" element={<IssueReportPage />} />
                  </Route>
                  <Route path="*" element={<NotFoundPage />} /> {/* Fallback route for unmatched URLs */}
              </Routes>
          </div>
      </Router>
  );
}

export default App;
