const SectionOne = () => {

    return (
        <div className="section top bottom-small overflow-hidden">
            <div className="container-default w-container" style={{ textAlign: 'left'}}>
                <div
                    data-w-id="099bcd9e-d954-1934-8b20-1548f3866a32"
                    style={{
                        transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                        opacity: 1,
                        transformStyle: 'preserve-3d',
                    }}
                    className="inner-container _1070px"
                >
                    <div className="big-font-title">About us</div>
                    <h1 className="display-1">Meet the team behind our work</h1>
                </div>

                <div className="mg-bottom-80px">
                    <div className="w-layout-grid grid-2-columns gap-column-32px gap-row-16px">
                        <div
                            id="w-node-_5078ec12-8080-677f-958b-e2b0a40d0c8d-de9904bb"
                            data-w-id="5078ec12-8080-677f-958b-e2b0a40d0c8d"
                            style={{
                                transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                                opacity: 1,
                                transformStyle: 'preserve-3d',
                            }}
                        >
                            <p className="mg-bottom-48px">
                                Get to know the creative minds and technical experts driving our innovative solutions. Our team is a diverse group of strategists, developers, and designers who bring passion, precision, and expertise to every project.
                            </p>
                            <div className="image-wrapper">
                                <img
                                    src="./About - Darkstudio X - Webflow Ecommerce website template_files/642482045e01142f1181a6a1_meet-the-team-behind-our-studio-image-left-darkstudio-x-webflow-template.jpg"
                                    alt="The Team Behind Our Studio - Darkstudio X Webflow Template"
                                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, (max-width: 1439px) 47vw, 640px"
                                    srcSet="https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/642482045e01142f1181a6a1_meet-the-team-behind-our-studio-image-left-darkstudio-x-webflow-template-p-500.jpg 500w,
                          https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/642482045e01142f1181a6a1_meet-the-team-behind-our-studio-image-left-darkstudio-x-webflow-template-p-800.jpg 800w,
                          https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/642482045e01142f1181a6a1_meet-the-team-behind-our-studio-image-left-darkstudio-x-webflow-template.jpg 1280w"
                                    className="image"
                                />
                            </div>
                        </div>

                        <div
                            id="w-node-_43a5958e-791d-75b8-4930-221ab5132e5a-de9904bb"
                            data-w-id="43a5958e-791d-75b8-4930-221ab5132e5a"
                            className="image-wrapper"
                            style={{
                                transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                                transformStyle: 'preserve-3d',
                                opacity: 1,
                            }}
                        >
                            <img
                                src="./About - Darkstudio X - Webflow Ecommerce website template_files/6424820497691030bbcd92f0_meet-the-team-behind-our-studio-image-right-darkstudio-x-webflow-template.jpg"
                                alt="The Team Behind Our Studio - Darkstudio X Webflow Template"
                                sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, (max-width: 1439px) 47vw, 640px"
                                srcSet="https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6424820497691030bbcd92f0_meet-the-team-behind-our-studio-image-right-darkstudio-x-webflow-template-p-500.jpg 500w,
                        https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6424820497691030bbcd92f0_meet-the-team-behind-our-studio-image-right-darkstudio-x-webflow-template-p-800.jpg 800w,
                        https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6424820497691030bbcd92f0_meet-the-team-behind-our-studio-image-right-darkstudio-x-webflow-template.jpg 1280w"
                                className="image"
                            />
                        </div>
                    </div>
                </div>

                <div
                    data-w-id="2ef12326-51b4-d5ee-f598-510d46caac42"
                    style={{
                        transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                        opacity: 1,
                        transformStyle: 'preserve-3d',
                    }}
                    className="w-layout-grid grid-4-columns stats-wrapper"
                >
                    <StatCard value="30+" label="Happy clients"/>
                    <StatCard value="40+" label="Amazing projects"/>
                    <StatCard value="100%" label="Clients satisfaction"/>
                    <StatCard value="10+" label="Team members"/>
                </div>

                <div
                    data-w-id="28a08eb2-7172-f0b8-86a2-f449be6b7803"
                    style={{opacity: 1}}
                    className="divider"
                ></div>
            </div>
        </div>
    )

}

const StatCard = ({ value, label }) => (
    <div
        style={{
            transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
            transformStyle: 'preserve-3d',
            opacity: 1,
        }}
    >
        <div className="display-2 mg-bottom-4px">{value}</div>
        <div className="text-400 color-neutral-500">{label}</div>
    </div>
);


export default SectionOne;
