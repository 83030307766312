import Header from "../../components/Header";
import Footer from "../../components/Footer";

const FormsPage = () => {

    return (
        <body>
        <div className="page-wrapper">
            <Header />
            <iframe className="clickup-embed clickup-dynamic-height"
                    src="https://forms.clickup.com/9014395346/f/8cmtgej-1434/9CCOZT9P5BZKYFGJKB" onWheel="" width="100%"
                    style={{background: 'transparent', border: '1px solid #ccc', height: '1500px'}}></iframe>
            <script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
            <Footer/>
        </div>
        </body>
    )

}

export default FormsPage;
