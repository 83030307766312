import Header from "../../components/Header";
import SectionOne from "./components/SectionOne";
import SectionTwo from "./components/SectionTwo";
import Footer from "../../components/Footer";
import SectionThree from "./components/SectionThree";

const ContactUsPage = () => {

    return(
        <body>
            <div className="page-wrapper">
                <Header />
                <SectionOne />
                <SectionTwo />
                <SectionThree />
                <Footer />
            </div>
        </body>
    )

}

export default ContactUsPage;
