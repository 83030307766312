import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SectionOne from "./components/SectionOne";
import SectionTwo from "./components/SectionTwo";

const PricingPage = () => {

    return (
        <body>
            <div className="page-wrapper">
                <Header />
                <SectionOne />
                <SectionTwo />
                <Footer />
            </div>
        </body>
    )

}

export default PricingPage
