import FAQItem from "./FAQItem";
import {useState} from "react";

const SectionThree = () => {

    const [expandedIndices, setExpandedIndices] = useState([]);

    function handleIndexToggle(index) {
        if (expandedIndices.includes(index)) {
            const updated = expandedIndices.filter((item) => item !== index)
            setExpandedIndices(updated)
        } else {
            const updated = expandedIndices.concat(index)
            setExpandedIndices(updated)
        }
    }

    return (
        <div className="section small bottom">
            <div className="container-default w-container">
                <div
                    data-w-id="40db9c31-9757-8273-1b7d-3e28a71127b6"
                    style={{
                        transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                        opacity: 1,
                        transformStyle: 'preserve-3d',
                    }}
                    className="inner-container _990px center"
                >
                    <div className="text-center mg-bottom-48px">
                        <div className="big-font-title display-2---size">FAQS</div>
                        <h2 className="display-2 mg-bottom-0">Frequently asked questions</h2>
                    </div>
                </div>
            </div>
            <div
                data-w-id="b38debc7-03ba-008f-8919-ff82e2479f56"
                style={{
                    transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                    opacity: 1,
                    transformStyle: 'preserve-3d',
                }}
            >
                <div className="w-layout-grid grid-1-column gap-row-0">
                    <FAQItem
                        index={0}
                        expandedIndices={expandedIndices}
                        handleIndexToggle={handleIndexToggle}
                        title="What industries do you specialize in?"
                        content="We work with a wide range of industries, including tech, healthcare, retail, and finance. Our team is skilled in creating tailored solutions for businesses of all types, ensuring your unique needs are met."
                    />
                    <FAQItem
                        index={1}
                        expandedIndices={expandedIndices}
                        handleIndexToggle={handleIndexToggle}
                        title="How long does it take to complete a project?"
                        content="Project timelines vary based on the scope and complexity of the work. We’ll provide a clear timeline and keep you updated throughout the process, ensuring on-time delivery and high-quality results."
                    />
                    <FAQItem
                        index={2}
                        expandedIndices={expandedIndices}
                        handleIndexToggle={handleIndexToggle}
                        title="Can you help with both mobile and web development?"
                        content="Absolutely! We specialize in both mobile app development and web platforms. Whether you need a responsive website or a custom mobile app, we have the expertise to deliver a seamless experience across all devices."
                    />
                    <FAQItem
                        index={3}
                        expandedIndices={expandedIndices}
                        handleIndexToggle={handleIndexToggle}
                        title="What happens after my project is launched?"
                        content="Our commitment doesn’t end at launch. We offer ongoing support and maintenance to ensure your project runs smoothly, with options for future updates and optimizations as your business evolves."
                    />
                </div>
            </div>
        </div>
    )

}

export default SectionThree
