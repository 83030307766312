import CenteredHeader from "../../components/CenteredHeader";
import ComingSoonSection from "../coming-soon/components/ComingSoonSection";
import Footer from "../../components/Footer";
import NotFoundSection from "./components/NotFoundSection";

const NotFoundPage = () => {

    return (
        <body>
            <div className="page-wrapper">
                <CenteredHeader/>
                <NotFoundSection />
                <Footer/>
            </div>
        </body>
    )

}

export default NotFoundPage
