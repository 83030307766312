import CenteredHeader from "../../components/CenteredHeader";
import Footer from "../../components/Footer";
import ComingSoonSection from "./components/ComingSoonSection";

const ComingSoonPage = () => {

    return (
        <body>
            <div className="page-wrapper">
                <CenteredHeader />
                <ComingSoonSection />
                <Footer />
            </div>
        </body>
    )

}

export default ComingSoonPage;
