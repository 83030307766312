import Image01 from "../../../assets/6424a05067751b1d85433af4_come-and-visit-one-of-our-offices-image-darkstudio-x-webflow-template.jpg"

const SectionThree = () => {

    return (
        <div className="section">
            <div className="container-default w-container" style={{ textAlign: 'left'}}>
                <div
                    style={{
                        transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                        opacity: 1,
                        transformStyle: 'preserve-3d',
                    }}
                    className="title-left---content-right mg-bottom-48px"
                >
                    <div className="inner-container _920px">
                        <div className="big-font-title display-2---size">Visit us</div>
                        <h2 className="display-2 mg-bottom-0">
                            Come and visit one of our offices around the world
                        </h2>
                    </div>
                    <a href="https://darkstudiotemplate.webflow.io/about#values" className="btn-secondary w-button">
                        Our values
                    </a>
                </div>
                <div className="position-relative">
                    <div
                        style={{opacity: 1}}
                        className="image-wrapper position-relative"
                    >
                        <div className="bg-overlay bg-neutral-800 opacity-60"></div>
                        <img
                            src={Image01}
                            alt="Visit One Of Our Offices - Darkstudio X Webflow Template"
                            className="image visit-us-image"
                        />
                    </div>
                    <div
                        style={{
                            transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                            opacity: 1,
                            transformStyle: 'preserve-3d',
                        }}
                        className="position-absolute contact-information-wrapper"
                    >
                        <div className="grid-3-columns section-visit-us---about-page">
                            <div>
                                <div className="mg-bottom-8px">
                                    <div className="text-300 text-uppercase color-neutral-300 regular">Location:</div>
                                </div>
                                <a
                                    href="https://www.google.com/maps/place/new+york"
                                    target="_blank"
                                    className="text-link contact-link w-inline-block"
                                >
                                    <div>Columbia, SC</div>
                                </a>
                            </div>
                            <div>
                                <div className="mg-bottom-8px">
                                    <div className="text-300 text-uppercase color-neutral-300 regular">Phone:</div>
                                </div>
                                <a href="tel:(786)498-3677" className="text-link contact-link w-inline-block">
                                    <div>(786) 498 - 3677</div>
                                </a>
                            </div>
                            <div>
                                <div className="mg-bottom-8px">
                                    <div className="text-300 text-uppercase color-neutral-300 regular">Email:</div>
                                </div>
                                <a href="mailto:hello@younivision.com" className="text-link contact-link w-inline-block">
                                    <div>hello@younivision.com</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SectionThree
