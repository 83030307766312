import {useState} from "react";
import FirebaseService from "../../../../helpers/FirebaseService";

const SectionOne = ({submitted}) => {

    const [projectName, setProjectName] = useState('');
    const [fullName, setFullName] = useState('');
    const [defineIssue, setDefineIssue] = useState('');
    const [reproductionSteps, setReproductionSteps] = useState('');
    const [assumedBehavior, setAssumedBehavior] = useState('');
    const [deviceTarget, setDeviceTarget] = useState('');
    const [impactArea, setImpactArea] = useState('');

    function handleProjectName(e) {
        setProjectName(e.target.value);
    }

    function handleFullName(e) {
        setFullName(e.target.value);
    }

    function handleDefineIssue(e) {
        setDefineIssue(e.target.value);
    }

    function handleReproductionSteps(e) {
        setReproductionSteps(e.target.value);
    }

    function handleAssumedBehavior(e) {
        setAssumedBehavior(e.target.value);
    }

    function handleDeviceTarget(e) {
        setDeviceTarget(e.target.value);
    }

    function handleImpactArea(e) {
        setImpactArea(e.target.value);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const payload = {
            project_name: projectName,
            full_name: fullName,
            define_issue: defineIssue,
            reproduction_steps: reproductionSteps,
            assumed_behavior: assumedBehavior,
            device_target: deviceTarget,
            impact_area: impactArea,
        }

        try {
            await FirebaseService.addDocument("issue-reports", payload);
            submitted()
        } catch (error) {
            alert("Failed to submit issue report. Please try again later.");
        }

        console.log(payload)
    }


    return (
        <div className="section small top">
            <div className="container-default w-container" style={{ textAlign: 'left'}}>
                <div
                    className="title-left---content-right mg-bottom-64px"
                    style={{
                        transform: 'translate3d(0px, 0%, 0px) scale(1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)',
                        opacity: 1,
                        transformStyle: 'preserve-3d',
                    }}
                >
                    <div className="inner-container _800px _100-tablet">
                        <div className="big-font-title">Client</div>
                        <h1 className="display-1 mg-bottom-0">Report a bug/issue</h1>
                    </div>
                    <div className="inner-container _475px _100-tablet">
                        <p className="mg-bottom-0">
                            Have an idea for a new feature? We’re here to help bring your vision to life. Share your request with us, and our team will work with you to integrate it.
                        </p>
                    </div>
                </div>
            </div>
            <div className="contact-form-wrapper" style={{opacity: 1, textAlign: 'left', height: 'auto'}}>
                <div className="container-default w-container">
                    <div className="w-layout-grid grid-2-columns contact-form-side-details">
                        <div className="grid-right-side">
                            <div className="grid-right-side---top">
                                <h2 className="display-4">More contact information</h2>
                                <p className="mg-bottom-40px">
                                    Whether you're looking for custom software development, a new mobile app, or innovative digital solutions, we're here to help.
                                </p>
                                <div className="grid-1-column gap-row-40px">
                                    <div>
                                        <div className="text-300 text-uppercase color-neutral-400">Send us an email
                                        </div>
                                        <a
                                            href="mailto:hello@younivision.com"
                                            className="link-wrapper mg-top-16px w-inline-block"
                                        >
                                            <div className="link-text">hello@younivision.com</div>
                                            <div className="line-square-icon link-icon-right"></div>
                                        </a>
                                    </div>
                                    <div>
                                        <div className="text-300 text-uppercase color-neutral-400">Give us a call</div>
                                        <a href="tel:(786)498-3677" className="link-wrapper mg-top-16px w-inline-block">
                                            <div className="link-text">(786) 498 - 3677</div>
                                            <div className="line-square-icon link-icon-right"></div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative">
                                <div className="divider grid-divider"></div>
                            </div>
                            <div className="grid-right-side---bottom">
                                <h3 className="display-4 mg-bottom-16px">Follow us</h3>
                                <p className="mg-bottom-24px">Lorem ipsum dolor sit amet consectetur id integer
                                    ipsum.</p>
                                <div className="w-layout-grid social-media-grid">
                                    <a href="https://facebook.com/" target="_blank"
                                       className="social-icon w-inline-block" rel="noopener noreferrer">
                                        <div className="social-icon-font"></div>
                                    </a>
                                    <a href="https://twitter.com/" target="_blank"
                                       className="social-icon w-inline-block" rel="noopener noreferrer">
                                        <div className="social-icon-font"></div>
                                    </a>
                                    <a href="https://www.instagram.com/" target="_blank"
                                       className="social-icon w-inline-block" rel="noopener noreferrer">
                                        <div className="social-icon-font"></div>
                                    </a>
                                    <a href="https://www.linkedin.com/" target="_blank"
                                       className="social-icon w-inline-block" rel="noopener noreferrer">
                                        <div className="social-icon-font"></div>
                                    </a>
                                </div>
                            </div>
                            <div className="contact-form---vertical-divider"></div>
                        </div>
                        <div className="grid-left-side">
                            <div className="mg-bottom-0 w-form">
                                <form
                                    onSubmit={(e) => handleSubmit(e)}
                                    id="wf-form-Contact-Page-Form"
                                    name="wf-form-Contact-Page-Form"
                                    method="get"
                                    className="form contact-form"
                                    aria-label="Request New Feature Form"
                                >
                                    <div className="w-layout-grid grid-2-columns form">
                                        <div>
                                            <label htmlFor="project_name">Project Name</label>
                                            <input
                                                onChange={(e) => handleProjectName(e)}
                                                className="input w-input"
                                                maxLength={256}
                                                name="project_name"
                                                placeholder="My Amazing Project"
                                                type="text"
                                                id="project_name"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="full_name">Full Name</label>
                                            <input
                                                onChange={(e) => handleFullName(e)}
                                                className="input w-input"
                                                maxLength={256}
                                                name="full_name"
                                                placeholder="John Carter"
                                                type="text"
                                                id="full_name"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="define_issue">What is the issue that you would like to report?
                                            </label>
                                            <textarea
                                                onChange={(e) => handleDefineIssue(e)}
                                                id="define_issue"
                                                name="define_issue"
                                                maxLength={5000}
                                                placeholder="Write your message here..."
                                                required
                                                className="text-area w-input"
                                            ></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="reprod">How do we reproduce this bug/issue. Please give
                                                details.</label>
                                            <textarea
                                                onChange={(e) => handleReproductionSteps(e)}
                                                id="reprod"
                                                name="reprod"
                                                maxLength={5000}
                                                placeholder="Write your message here..."
                                                className="text-area w-input"
                                            ></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="assumed_behavior">What did you expect to happen?
                                            </label>
                                            <textarea
                                                onChange={(e) => handleAssumedBehavior(e)}
                                                id="assumed_behavior"
                                                name="assumed_behavior"
                                                maxLength={5000}
                                                placeholder="Write your message here..."
                                                className="text-area w-input"
                                            ></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="device_target">Which device or environment was used? (OS,
                                                browser, etc.)</label>
                                            <textarea
                                                onChange={(e) => handleDeviceTarget(e)}
                                                id="device_target"
                                                name="device_target"
                                                maxLength={5000}
                                                placeholder="Write your message here..."
                                                required
                                                className="text-area w-input"
                                            ></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="impact_area">How large of an impact does this have on your
                                                company?</label>
                                            <input
                                                onChange={(e) => handleImpactArea(e)}
                                                className="input w-input"
                                                maxLength={256}
                                                name="impact_area"
                                                placeholder="Ex. Large"
                                                type="text"
                                                id="Name-2"
                                                required
                                            />
                                        </div>
                                        <input
                                            type="submit"
                                            className="btn-secondary w-button"
                                            value="Submit Issue"
                                        />
                                    </div>
                                </form>
                                <div className="success-message contact-form w-form-done" role="region"
                                     aria-label="Contact Page Form success">
                                    <div className="success-message-wrapper contact-form">
                                        <div className="line-square-icon success-message-icon-top"></div>
                                        <h3 className="color-neutral-100">Thank you</h3>
                                        <div>Thanks for reaching out. We will get back to you soon.</div>
                                    </div>
                                </div>
                                <div className="error-message w-form-fail" role="region"
                                     aria-label="Contact Page Form failure">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default SectionOne;
