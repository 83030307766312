import Icon01 from "../../../assets/6423473ee7c6dd7e31245531_editorial-design-icon-darkstudio-x-webflow-template.svg";
import Icon02 from "../../../assets/6423473d7c68c8ca767c0f7d_packagin-design-icon-darkstudio-x-webflow-template.svg";
import Icon03 from "../../../assets/6423473e45a6d53a4205d7a0_stationary-design-icon-darkstudio-x-webflow-template.svg";

const ServicesSection = () => {

    return (
        <div className="section">
            <div className="container-default w-container" style={{alignItems: 'start'}}>
                <div data-w-id="0e41b14b-387f-3091-80bb-2847ab686595"
                     style={{
                         transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         opacity: 1,
                         transformStyle: 'preserve-3d',
                     }}
                     className="inner-container _1068px">
                    <div className="big-font-title display-2---size" style={{textAlign: 'left'}}>Services</div>
                    <h2 className="display-2 mg-bottom-32px" style={{textAlign: 'left'}}>We offer a wide range of brand
                        services</h2>
                    <div style={{
                        flex: 'auto',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        alignContent: 'flex-start',
                        justifyContent: 'flex-start'
                    }}>
                        <a href="/pricing"
                           className="btn-secondary white w-button">Browse all services</a>
                    </div>
                </div>
                <div className="w-layout-grid grid-3-columns services-section---home-page">
                    <div data-w-id="12b37804-1f66-7bf2-2931-4a534aee7700"
                         style={{
                             transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             opacity: 1,
                             transformStyle: 'preserve-3d'
                         }}
                         className="service-card-wrapper---left">
                        <div id="w-node-_39692e6f-34d1-7571-1a5a-35f45e58660d-1d76e104"
                             className="inner-container _385px _100-tablet" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'flex-start',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start'
                        }}>
                            <img
                                src={Icon01}
                                alt="Editorial Design - Darkstudio X Webflow Template"
                                className="square-icon mg-bottom-24px"/>
                            <h3 className="display-3 mg-bottom-12px" style={{textAlign: 'left'}}>Mobile App Development</h3>
                            <p className="mg-bottom-0" style={{textAlign: 'left'}}>
                                Our team creates seamless, user-friendly apps for iOS and Android, designed to engage users and drive business results.
                            </p>
                        </div>
                    </div>
                    <div data-w-id="dd42dea2-3ffb-aa80-249f-dff7ca5dd000"
                         className="service-card-wrapper---center"
                         style={{
                             transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d',
                             opacity: 1
                         }}>
                        <div id="w-node-fea2638b-929b-4d7d-5d35-7dbf1b4597a5-1d76e104"
                             className="inner-container _385px _100-tablet" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'flex-start',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start'
                        }}>
                            <img
                                src={Icon02}
                                alt="Packaging Desing - Darkstudio X Webflow Template"
                                className="square-icon mg-bottom-24px"/>
                            <h3 className="display-3 mg-bottom-12px" style={{textAlign: 'left'}}>Web platforms</h3>
                            <p className="mg-bottom-0" style={{textAlign: 'left'}}>
                                From e-commerce to custom portals, we deliver fast, secure, and responsive solutions that grow with your business.
                            </p>
                        </div>
                    </div>
                    <div id="w-node-ded3e644-bb5a-a9bd-8ec2-94fd1f669ce0-1d76e104"
                         data-w-id="ded3e644-bb5a-a9bd-8ec2-94fd1f669ce0"
                         className="service-card-wrapper---right"
                         style={{
                             transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d',
                             opacity: 1
                         }}>
                        <div id="w-node-d893b7e4-8ade-0cf5-765b-325b6c418e42-1d76e104"
                             className="inner-container _385px _100-tablet" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'flex-start',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start'
                        }}>
                            <img
                                src={Icon03}
                                alt="Stationary Design - Darkstudio X Webflow Template"
                                className="square-icon mg-bottom-24px"/>
                            <h3 className="display-3 mg-bottom-12px" style={{textAlign: 'left'}}>Graphic design</h3>
                            <p className="mg-bottom-0" style={{textAlign: 'left'}}>
                                Our design experts craft logos, branding, and digital assets that capture your unique identity and make a lasting impression.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ServicesSection
