import { firestore } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

const FirebaseService = {

    // Helper function to add a new document to Firestore collection
    async addDocument(collectionName, documentData) {
        try {
            const docRef = await addDoc(collection(firestore, collectionName), documentData);
            console.log("Document successfully written with ID: ", docRef.id);
            return docRef.id;
        } catch (error) {
            console.error("Error adding document: ", error);
            throw new Error("Error adding document");
        }
    }

}

export default FirebaseService;
