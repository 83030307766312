import Image01 from "../assets/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template.jpg"

const SubmittedSection = ({text}) => {

    return (
        <div className="section section-hero---coming-soon-page">
            <div className="container-default w-container">
                <div className="w-layout-grid grid-2-columns coming-soon-grid">
                    <div style={{ zIndex: 2, textAlign: 'left'}}
                        id="w-node-_09359ef1-59e8-4102-bb01-564c2e2a4195-7b4d5e6e"
                        className="position-relative z-index-1"
                    >
                        <div
                            data-w-id="16d45e23-9ccd-1070-1237-5f79220cfbec"
                            style={{
                                transform:
                                    "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="inner-container _770px _100-tablet"
                        >
                            <h1 className="display-1">{text} Submitted</h1>
                            <div className="inner-container _745px _100-tablet">
                                <p className="mg-bottom-56px">
                                    Lorem ipsum dolor sit amet consectetur. Id integer ipsum tristique tellus adipiscing
                                    ornare eget eu urna curabitur feugiat viverra feugiat scelerisque.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div
                        data-w-id="352ed507-6e21-a732-d1ce-47d6a55e3ef5"
                        style={{opacity: 1}}
                        className="image-wrapper coming-soon-image"
                    >
                        <div className="opacity-20---tablet">
                            <img
                                src={Image01}
                                alt="Coming Soon - Darkstudio X Webflow Template"
                                sizes="(max-width: 1439px) 100vw, 1440px"
                                srcSet="
                            https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template-p-500.jpg   500w,
                            https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template-p-800.jpg   800w,
                            https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template-p-1080.jpg 1080w,
                            https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template-p-1600.jpg 1600w,
                            https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template.jpg        2880w
                        "
                                className="image coming-soon"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SubmittedSection;
