import CustomerFirstIcon from '../../../assets/6424a9f732e3235551bbc201_customer-first-icon-darkstudio-x-webflow-template.svg';
import PassionIcon from '../../../assets/6424a9f66da9f04017cba53d_passion-icon-darkstudio-x-webflow-template.svg';
import CommitmentIcon from '../../../assets/6424a9f75dc502132e9e5b39_commitment-icon-darkstudio-x-webflow-template.svg';
import InnovationIcon from '../../../assets/6424a9f7b0aaffe39279a51d_innovation-icon-darkstudio-x-webflow-template.svg';
import OpenessIcon from '../../../assets/6424a9f68c57ee42252925cc_openness-icon-darkstudio-x-webflow-template.svg';
import AccountabilityIcon from '../../../assets/6424a9f7386473e99d494c33_accountability-icon-darkstudio-x-webflow-template.svg';

const SectionFour = () => {

    const OPTIONS = [
        {title: 'Customer first', icon: CustomerFirstIcon, description: 'We prioritize our clients’ needs, ensuring every solution aligns with their goals and vision.'},
        {title: 'Passion', icon: PassionIcon, description: 'Our team is driven by a love for technology and a dedication to creating impactful solutions.'},
        {title: 'Commitment', icon: CommitmentIcon, description: 'We stay devoted to delivering excellence and reliability in every project we undertake.'},
        {title: 'Innovation', icon: InnovationIcon, description: 'We embrace creativity and forward-thinking, always seeking new ways to solve challenges.'},
        {title: 'Openness', icon: OpenessIcon, description: 'Transparent communication and collaboration are at the core of how we work with clients.'},
        {title: 'Accountability', icon: AccountabilityIcon, description: 'We take full responsibility for our work, striving for quality and standing by our results.'}
    ]
    return (
        <div id="values" className="section">
            <div className="container-default w-container">
                <div
                    style={{
                        transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                        opacity: 1,
                        transformStyle: 'preserve-3d',
                    }}
                    className="inner-container _1150px center"
                >
                    <div className="text-center mg-bottom-48px">
                        <div className="big-font-title display-2---size">Our values</div>
                        <h2 className="display-2 mg-bottom-0">The core values that drive everything we do</h2>
                    </div>
                </div>
                <div style={{ paddingTop: '100px'}} className="w-layout-grid grid-3-columns gap-row-56px gap-column-32px">
                    {OPTIONS.map((value, index) => (
                        <div
                            key={index}
                            style={{
                                transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                                opacity: 1,
                                transformStyle: 'preserve-3d',
                            }}
                            className={`values-grid---${index % 2 === 0 ? 'top' : 'bottom'}-${['left', 'center', 'right'][index % 3]}`}
                        >
                            <div className="flex-vertical center text-center">
                                <img
                                    src={value?.icon ? value?.icon : null}
                                    className="square-icon small mg-bottom-24px"
                                 alt={''}/>
                                <h3 className="display-4 mg-bottom-12px">{value?.title}</h3>
                                <p className="mg-bottom-0">{value?.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

}

export default SectionFour;
