import Header from "../../../components/Header";
import SectionOne from "./components/SectionOne";
import Footer from "../../../components/Footer";
import {useState} from "react";
import SubmittedSection from "../../../components/SubmittedSection";

const IssueReportPage = () => {

    const [submitted, setSubmitted] = useState(false);

    function handleSubmissionSuccess() {
        setSubmitted(true)
    }

    return(
        <body>
            <div className="page-wrapper">
                <Header />
                {!submitted ? (
                    <SectionOne submitted={() => handleSubmissionSuccess()}/>
                ): (
                    <SubmittedSection text={'Issue Report'}/>
                )}
                <Footer />
            </div>
        </body>
    )

}

export default IssueReportPage;
