import ImageMain02
    from "../../../assets/64233fe30f030599b6a31019_we-are-an-extension-of-your-creative-team-image-darkstudio-x-webflow-template.jpg";

const AboutUsSection = () => {

    return (
        <div className="section">
            <div className="container-default w-container">
                <div className="position-relative z-index-2">
                    <div className="title-wrapper">
                        <div className="flex-horizontal end">
                            <div data-w-id="a221702c-d2a4-39ba-13cf-5293373f4f19"
                                 style={{
                                     transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                     opacity: 1,
                                     transformStyle: 'preserve-3d'
                                 }}
                                 className="inner-container _1070px">
                                <div
                                    className="big-font-title display-2---size text-right-desktop-and-tablet">About
                                    us
                                </div>
                                <h2 className="display-2 mg-bottom-0" style={{textAlign: 'left'}}>We are an extension of
                                    your creative
                                    team</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="position-relative z-index-1">
                    <div className="w-layout-grid grid-2-columns section-about---home-page">
                        <div id="w-node-_199c6d2c-7c44-e121-cbbf-cf3db0dd38b4-1d76e104"
                             data-w-id="199c6d2c-7c44-e121-cbbf-cf3db0dd38b4"
                             style={{
                                 transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                 opacity: 1,
                                 transformStyle: 'preserve-3d'
                             }}
                             className="inner-container _752px _100-tablet">
                            <div className="image-wrapper"><img
                                src={ImageMain02}
                                alt="Younivision"
                                sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, (max-width: 1439px) 55vw, 752px"
                                srcSet="https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/64233fe30f030599b6a31019_we-are-an-extension-of-your-creative-team-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/64233fe30f030599b6a31019_we-are-an-extension-of-your-creative-team-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/64233fe30f030599b6a31019_we-are-an-extension-of-your-creative-team-image-darkstudio-x-webflow-template.jpg 1504w"
                                className="image"/></div>
                        </div>
                        <div id="w-node-_23b5289b-73f3-3f30-f527-a1f827ab35af-1d76e104"
                             className="mg-top-80px mg-top-0px---tablet">
                            <div id="w-node-_49a98abe-1d53-3b96-6b5e-f515003cb3ec-1d76e104"
                                 data-w-id="49a98abe-1d53-3b96-6b5e-f515003cb3ec"
                                 className="inner-container _500px _100-tablet"
                                 style={{
                                     transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                     transformStyle: 'preserve-3d',
                                     opacity: 1
                                 }}>
                                <div className="mg-bottom-40px">
                                    <div className="rich-text mg-bottom--24px w-richtext">
                                        <p style={{textAlign: 'left'}}>
                                            We’re more than just a software development agency—we’re a dedicated extension of your creative team. Our collaborative approach bridges the gap between ideas and execution, working closely with you to understand your vision, goals, and brand identity.
                                        </p>
                                        <p style={{textAlign: 'left'}}>
                                            From strategy and design to implementation and launch, we seamlessly integrate with your in-house team, empowering you with expert developers, designers, and technical innovators who are as passionate about your success as you are.
                                        </p>
                                    </div>
                                </div>
                                <div className="buttons-row"><a
                                    href="/about"
                                    className="btn-secondary white w-button">Our story</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AboutUsSection;
