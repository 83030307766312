import YVSNHeaderLogo from "../assets/custom/YVSN_EMBLEM_COLOR.svg";
import {useState} from "react";

const Header = () => {

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div data-animation="default" className="header-wrapper w-nav" data-easing2="ease-out-sine"
             data-easing="ease-out-sine" data-collapse="all" data-w-id="e5acff6c-5c21-62ec-86e5-c6f09f07f69e"
             role="banner" data-no-scroll="1" data-duration="0" data-doc-height="1" style={{opacity: 1}}>
            <div className="container-default w-container">
                {menuOpen && (
                    <nav role="navigation" className="header-nav-menu-wrapper hamburger-desktop w-nav-menu"
                         style={{display: 'inline-block'}}>
                        <div className="header-menu-bg" style={{opacity: 1}}></div>
                        <div className="header-nav-menu top"
                             style={{
                                 opacity: 1,
                                 transform: 'translate3d(0px, 50px, 0px) scale3d(0.98, 0.98, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                 transformStyle: 'preserve-3d'
                             }}>
                            <ul role="list" className="header-nav-menu-list hamburger-desktop">
                                <li className="header-nav-list-item hamburger-desktop"><a
                                    href="/"
                                    data-w-id="e5acff6c-5c21-62ec-86e5-c6f09f07f6a4" aria-current="page"
                                    className="header-nav-link w-nav-link w--current"
                                    style={{maxHeight: '1360px'}}>Home<span
                                    className="line-square-icon link-icon-right large"></span></a></li>
                                <li className="header-nav-list-item hamburger-desktop"><a
                                    href="/about"
                                    data-w-id="e5acff6c-5c21-62ec-86e5-c6f09f07f6a9"
                                    className="header-nav-link w-nav-link" style={{maxHeight: '1360px;'}}>About<span
                                    className="line-square-icon link-icon-right large"></span></a></li>
                                <li className="header-nav-list-item hamburger-desktop"><a
                                    href="/articles"
                                    data-w-id="e5acff6c-5c21-62ec-86e5-c6f09f07f6ae"
                                    className="header-nav-link w-nav-link" style={{maxWidth: '1360px'}}>Articles<span
                                    className="line-square-icon link-icon-right large"></span></a></li>
                                <li className="header-nav-list-item hamburger-desktop"><a
                                    href="/projects"
                                    data-w-id="e5acff6c-5c21-62ec-86e5-c6f09f07f6b3"
                                    className="header-nav-link w-nav-link" style={{maxWidth: '1360px'}}>Client Projects<span
                                    className="line-square-icon link-icon-right large"></span></a></li>
                                <li className="header-nav-list-item hamburger-desktop"><a
                                    href="/pricing"
                                    data-w-id="e5acff6c-5c21-62ec-86e5-c6f09f07f6b8"
                                    className="header-nav-link w-nav-link" style={{maxWidth: '1360px'}}>Pricing<span
                                    className="line-square-icon link-icon-right large"></span></a></li>
                                <li className="header-nav-list-item hamburger-desktop"><a
                                    href="/contact"
                                    data-w-id="24b20874-fcde-c41a-fad6-88c7ea3b7767"
                                    className="header-nav-link w-nav-link" style={{maxWidth: '1360px'}}>Contact Us<span
                                    className="line-square-icon link-icon-right large"></span></a></li>
                            </ul>
                        </div>
                        <div className="header-nav-menu middle"
                             style={{
                                 opacity: 0,
                                 transform: 'translate3d(0px, 50px, 0px) scale3d(0.98, 0.98, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                 transformStyle: 'preserve-3d'
                             }}>
                            <a href="https://brixtemplates.com/more-webflow-templates"
                               data-w-id="e5acff6c-5c21-62ec-86e5-c6f09f07f6c2" target="_blank"
                               className="header-nav-link w-nav-link" style={{maxWidth: '1360px'}}>Browse more
                                templates<span className="line-square-icon link-icon-right large"></span></a></div>
                        <div className="header-nav-menu bottom"
                             style={{
                                 opacity: 0,
                                 transform: 'translate3d(0px, 50px, 0px) scale3d(0.98, 0.98, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                 transformStyle: 'preserve-3d'
                             }}>
                            <div className="w-layout-grid social-media-grid center">
                                <a href="https://facebook.com/" target="_blank"
                                   className="social-icon w-inline-block" rel="noreferrer">
                                    <div className="social-icon-font"></div>
                                </a>
                                <a href="https://twitter.com/" target="_blank"
                                   className="social-icon w-inline-block" rel="noreferrer">
                                    <div className="social-icon-font"></div>
                                </a>
                                <a href="https://www.instagram.com/" target="_blank"
                                   className="social-icon w-inline-block" rel="noreferrer">
                                    <div className="social-icon-font"></div>
                                </a>
                                <a href="https://www.linkedin.com/" target="_blank"
                                   className="social-icon w-inline-block" rel="noreferrer">
                                    <div className="social-icon-font"></div>
                                </a>
                            </div>
                        </div>
                    </nav>
                )}
                <div className="header-content-wrapper">
                    <a href="/" aria-current="page"
                       className="header-logo-link w-nav-brand w--current"><img
                        src={YVSNHeaderLogo}
                        alt="Darkstudio X Webflow Template - Logo"/></a>
                    <div className="header-right-content">
                        <a data-w-id="e5acff6c-5c21-62ec-86e5-c6f09f07f6d8" href="mailto:hello@younivision.com"
                           className="link-wrapper hidden-on-tablet w-inline-block">
                            <div className="link-text">HELLO@YOUNIVISION.com</div>
                            <div className="line-square-icon link-icon-right"></div>
                        </a>

                        <div onClick={() => setMenuOpen(!menuOpen)} data-w-id="e5acff6c-5c21-62ec-86e5-c6f09f07f6dd"
                             className="hamburger-menu-wrapper w-nav-button" style={{userSelect: 'text'}}
                             aria-label="menu" role="button" tabIndex="0" aria-controls="w-nav-overlay-0"
                             aria-haspopup="menu" aria-expanded="false">
                            <div className="hamburger-menu-bar top" style={{width: '50px'}}></div>
                            <div className="hamburger-menu-bar center" style={{width: '50px'}}></div>
                            <div className="hamburger-menu-bar bottom" style={{width: '50px'}}></div>
                            <div className="hamburger-menu-bar top-left">
                                <div className="hamburger-menu-bar-filler first"></div>
                            </div>
                            <div className="hamburger-menu-bar bottom-left">
                                <div className="hamburger-menu-bar-filler last"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
        </div>
    )

}

export default Header;
