const SectionOne = () => {

    return (
        <div className="section small top">
            <div className="container-default w-container" style={{ textAlign: 'left'}}>
                <div
                    className="title-left---content-right mg-bottom-64px"
                    style={{
                        transform: 'translate3d(0px, 0%, 0px) scale(1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)',
                        opacity: 1,
                        transformStyle: 'preserve-3d',
                    }}
                >
                    <div className="inner-container _800px _100-tablet">
                        <div className="big-font-title">Contact</div>
                        <h1 className="display-1 mg-bottom-0">Let’s work together</h1>
                    </div>
                    <div className="inner-container _475px _100-tablet">
                        <p className="mg-bottom-0">
                            Ready to bring your ideas to life? We're excited to collaborate with you and turn your vision into reality.
                        </p>
                    </div>
                </div>
            </div>
            <div className="contact-form-wrapper" style={{opacity: 1, textAlign: 'left'}}>
                <div className="container-default w-container">
                    <div className="w-layout-grid grid-2-columns contact-form-side-details">
                        <div className="grid-right-side">
                            <div className="grid-right-side---top">
                                <h2 className="display-4">More contact information</h2>
                                <p className="mg-bottom-40px">
                                    Whether you're looking for custom software development, a new mobile app, or innovative digital solutions, we're here to help.
                                </p>
                                <div className="grid-1-column gap-row-40px">
                                    <div>
                                        <div className="text-300 text-uppercase color-neutral-400">Send us an email
                                        </div>
                                        <a
                                            href="mailto:hello@younivision.com"
                                            className="link-wrapper mg-top-16px w-inline-block"
                                        >
                                            <div className="link-text">hello@younivision.com</div>
                                            <div className="line-square-icon link-icon-right"></div>
                                        </a>
                                    </div>
                                    <div>
                                        <div className="text-300 text-uppercase color-neutral-400">Give us a call</div>
                                        <a href="tel:(786)498-3677" className="link-wrapper mg-top-16px w-inline-block">
                                            <div className="link-text">(786) 498 - 3677</div>
                                            <div className="line-square-icon link-icon-right"></div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative">
                                <div className="divider grid-divider"></div>
                            </div>
                            <div className="grid-right-side---bottom">
                                <h3 className="display-4 mg-bottom-16px">Follow us</h3>
                                <p className="mg-bottom-24px">Lorem ipsum dolor sit amet consectetur id integer
                                    ipsum.</p>
                                <div className="w-layout-grid social-media-grid">
                                    <a href="https://facebook.com/" target="_blank"
                                       className="social-icon w-inline-block" rel="noopener noreferrer">
                                        <div className="social-icon-font"></div>
                                    </a>
                                    <a href="https://twitter.com/" target="_blank"
                                       className="social-icon w-inline-block" rel="noopener noreferrer">
                                        <div className="social-icon-font"></div>
                                    </a>
                                    <a href="https://www.instagram.com/" target="_blank"
                                       className="social-icon w-inline-block" rel="noopener noreferrer">
                                        <div className="social-icon-font"></div>
                                    </a>
                                    <a href="https://www.linkedin.com/" target="_blank"
                                       className="social-icon w-inline-block" rel="noopener noreferrer">
                                        <div className="social-icon-font"></div>
                                    </a>
                                </div>
                            </div>
                            <div className="contact-form---vertical-divider"></div>
                        </div>
                        <div className="grid-left-side">
                            <div className="mg-bottom-0 w-form">
                                <form
                                    id="wf-form-Contact-Page-Form"
                                    name="wf-form-Contact-Page-Form"
                                    method="get"
                                    className="form contact-form"
                                    aria-label="Contact Page Form"
                                >
                                    <div className="w-layout-grid grid-2-columns form">
                                        <div>
                                            <label htmlFor="Name-2">Full name</label>
                                            <input
                                                className="input w-input"
                                                maxLength={256}
                                                name="Name-2"
                                                placeholder="John Carter"
                                                type="text"
                                                id="Name-2"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="Email-2">Email address</label>
                                            <input
                                                className="input w-input"
                                                maxLength={256}
                                                name="Email-2"
                                                placeholder="example@email.com"
                                                type="email"
                                                id="Email-2"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="Phone-2">Phone number</label>
                                            <input
                                                className="input w-input"
                                                maxLength={256}
                                                name="Phone-2"
                                                placeholder="(123) 456 - 7890"
                                                type="tel"
                                                id="Phone-2"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="Company-2">Company</label>
                                            <input
                                                className="input w-input"
                                                maxLength={256}
                                                name="Company-2"
                                                placeholder="Ex. Facebook"
                                                type="text"
                                                id="Company-2"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="Message-2">Message</label>
                                            <textarea
                                                id="Message-2"
                                                name="Message-2"
                                                maxLength={5000}
                                                placeholder="Write your message here..."
                                                required
                                                className="text-area w-input"
                                            ></textarea>
                                        </div>
                                        <input
                                            type="submit"
                                            className="btn-secondary w-button"
                                            value="Send Message"
                                        />
                                    </div>
                                </form>
                                <div className="success-message contact-form w-form-done" role="region"
                                     aria-label="Contact Page Form success">
                                    <div className="success-message-wrapper contact-form">
                                        <div className="line-square-icon success-message-icon-top"></div>
                                        <h3 className="color-neutral-100">Thank you</h3>
                                        <div>Thanks for reaching out. We will get back to you soon.</div>
                                    </div>
                                </div>
                                <div className="error-message w-form-fail" role="region"
                                     aria-label="Contact Page Form failure">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default SectionOne;
