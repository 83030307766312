import Image01 from "../../../assets/64249aff12614de93c6a8eb2_we-started-in-2012-with-a-goal-in-mind-image-darkstudio-x-webflow-template.jpg"
import Image02 from "../../../assets/64249affb2ea501019baec4f_over-200-projects-done-image-darkstudio-x-webflow-template.jpg"

const SectionTwo = () => {

    return (
        <div className="section overflow-hidden" style={{ textAlign: 'left'}}>
            <div className="container-default w-container">
                <div className="flex-horizontal end mg-bottom-32px">
                    <div
                        style={{
                            transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                            opacity: 1,
                            transformStyle: 'preserve-3d',
                        }}
                        className="inner-container _920px"
                    >
                        <h2 className="display-2 mg-bottom-0">We started in 2012 with a goal in mind</h2>
                        <div className="big-font-title display-2---size mg-top--132px">Our story</div>
                    </div>
                </div>
                <div className="w-layout-grid grid-1-column gap-row-72px">
                    <div className="w-layout-grid grid-2-columns _1fr---0-7fr">
                        <div className="inner-container _725px">
                            <div
                                style={{
                                    transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1)',
                                    opacity: 1,
                                    transformStyle: 'preserve-3d',
                                }}
                                className="image-wrapper"
                            >
                                <img
                                    src={Image01}
                                    alt="We Started in 2012 - Darkstudio X Webflow Template"
                                    className="image"
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                                opacity: 1,
                                transformStyle: 'preserve-3d',
                            }}
                            className="inner-container _500px _100-tablet"
                        >
                            <p className="mg-bottom-24px">
                                Back in 2012, we set out with a clear mission: to build innovative, high-quality tech solutions that empower businesses and drive growth.
                            </p>
                            <p className="mg-bottom-32px">
                                Over the years, our dedication to that goal has fueled our evolution, allowing us to deliver impactful digital experiences that help our clients succeed in an ever-changing landscape.
                            </p>
                            <div className="buttons-row wrap">
                                <a href="https://darkstudiotemplate.webflow.io/contact"
                                   className="btn-secondary button-row w-button">
                                    Contact us
                                </a>
                                <a href="https://darkstudiotemplate.webflow.io/portfolio"
                                   className="btn-secondary white w-button">
                                    Our projects
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-grid grid-2-columns _1fr---0-9fr">
                        <div className="position-relative z-index-1">
                            <div
                                style={{
                                    transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                                    opacity: 1,
                                    transformStyle: 'preserve-3d',
                                }}
                                className="inner-container _680px _100-tablet"
                            >
                                <h3 className="display-2 mg-bottom-32px">Over 30 projects done</h3>
                                <div className="inner-container _500px _100-tablet">
                                    <p className="mg-bottom-24px">
                                        With over 30 successful projects under our belt, we bring a wealth of experience and a proven track record to every new challenge. Our team has partnered with clients across diverse industries, delivering tailored solutions that meet unique needs and exceed expectations.
                                    </p>
                                    <p className="mg-bottom-0">
                                        Our commitment to excellence has earned us lasting relationships and consistent results. We don’t just complete projects—we create solutions that make a difference, helping our clients grow and stay ahead in an increasingly competitive digital world.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1)',
                                opacity: 1,
                                transformStyle: 'preserve-3d',
                            }}
                            className="image-wrapper section-our-story---about-page---image-bottom"
                        >
                            <img
                                src={Image02}
                                alt="Over 200 Projects Done - Darkstudio X Webflow Template"
                                className="image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SectionTwo;
