import ImageMain
    from "../../../assets/64232af5f560211abb908309_a-creative-brand-studio-from-san-francisco-darkstudio-x-webflow-template.png";

const HeroSection = () => {

    return (
        <div className="section home-hero-section">
            <div className="container-default w-container">
                <div className="w-layout-grid grid-1-column gap-row-64px">
                    <div data-w-id="062d0fbe-9e9d-02be-f036-dfd0797421e5"
                         style={{
                             transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             opacity: 1,
                             transformStyle: 'preserve-3d',
                             zIndex: 2
                         }}
                         className="inner-container _1070px">
                        <div className="big-font-title" style={{textAlign: 'left'}}>YNVSN</div>
                        <h1 className="display-1" style={{textAlign: 'left'}}>Industry Leading Tech Solutions<br/>In
                            South Carolina</h1>
                        <div className="inner-container _510px _100-tablet">
                            <p className="mg-bottom-40px">
                                Empowering businesses worldwide with cutting-edge software and digital innovation.
                            </p>
                        </div>
                        <div className="buttons-row"><a href="/contact"
                                                        className="btn-secondary button-row w-button">Contact
                            us</a><a href="/projects"
                                     className="btn-secondary white w-button">Our projects</a></div>
                    </div>
                    <div id="w-node-_22495262-4428-237f-1b1f-af418b714aec-1d76e104"
                         data-w-id="22495262-4428-237f-1b1f-af418b714aec" style={{opacity: 1}}
                         className="image-wrapper section-hero---home">
                        <img
                            src={ImageMain}
                            alt="Younivision"
                            className="image"/>
                        <div className="bg-overlay home-hero-image"></div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default HeroSection;
