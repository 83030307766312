const ArticlesSection = () => {

    return (
        <div className="section bottom">
            <div className="container-default w-container">
                <div data-w-id="705812b7-2e1e-4fa4-eb50-39eff254e647"
                     style={{
                         transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         opacity: 1,
                         transformStyle: 'preserve-3d'
                     }}
                     className="title-left---content-right mg-bottom-48px">
                    <div>
                        <div className="big-font-title display-2---size">Our blog</div>
                        <h2 className="display-2 mg-bottom-0">News &amp; articles</h2>
                    </div>
                    <a href="/blog"
                       className="btn-secondary white w-button">Browse all articles</a>
                </div>
                <div className="w-dyn-list">
                    <div role="list" className="grid-2-columns _1-col-tablet gap-48px w-dyn-items">
                        <div id="w-node-b3e02578-7a9c-1d59-58f9-671407804af7-1d76e104"
                             data-w-id="b3e02578-7a9c-1d59-58f9-671407804af7" role="listitem"
                             className="blur-sibling-item w-dyn-item">
                            <a data-w-id="30c3bc19-58b2-216a-28f5-abb09375a3b6"
                               href="/"
                               className="blog-card w-inline-block"
                               style={{
                                   transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                   transformStyle: 'preserve-3d',
                                   opacity: 1
                               }}>
                                <div>
                                    <div className="image-wrapper mg-bottom-32px"><img
                                        src="../assets/64247aaf7c11de1e23b433d9_the-diference-between-ux-and-ui-thumbnail-image-darkstudio-x-webflow-template.jpg"
                                        alt="The difference between UX &amp; UI Design: A guide for beginNer’s"
                                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, (max-width: 1439px) 45vw, 632px"
                                        srcSet="https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64247aaf7c11de1e23b433d9_the-diference-between-ux-and-ui-thumbnail-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64247aaf7c11de1e23b433d9_the-diference-between-ux-and-ui-thumbnail-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64247aaf7c11de1e23b433d9_the-diference-between-ux-and-ui-thumbnail-image-darkstudio-x-webflow-template.jpg 1264w"
                                        className="image"/></div>
                                    <h3 className="display-4 mg-bottom-24px title" style={{textAlign: 'left'}}>The
                                        difference between
                                        UX &amp; UI Design: A guide for beginNer’s</h3>
                                </div>
                                <div className="flex-shrink">
                                    <div className="flex-horizontal start">
                                        <div className="text-200 color-neutral-400">John Carter</div>
                                        <div className="divider-vertical small bg-neutral-600"></div>
                                        <div className="text-200 color-neutral-400">Mar 30, 2023</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="w-node-b3e02578-7a9c-1d59-58f9-671407804af7-1d76e104"
                             data-w-id="b3e02578-7a9c-1d59-58f9-671407804af7" role="listitem"
                             className="blur-sibling-item w-dyn-item">
                            <a data-w-id="30c3bc19-58b2-216a-28f5-abb09375a3b6"
                               href="/"
                               className="blog-card w-inline-block"
                               style={{
                                   transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                   transformStyle: 'preserve-3d',
                                   opacity: 1
                               }}>
                                <div>
                                    <div className="image-wrapper mg-bottom-32px"><img
                                        src="../assets/64247a88955a236b6616050a_what-did-we-learn-from-running-thumbnail-image-darkstudio-x-webflow-template.jpg"
                                        alt="What did we learn from running 100 design sprints this year"
                                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, (max-width: 1439px) 45vw, 632px"
                                        srcSet="https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64247a88955a236b6616050a_what-did-we-learn-from-running-thumbnail-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64247a88955a236b6616050a_what-did-we-learn-from-running-thumbnail-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64247a88955a236b6616050a_what-did-we-learn-from-running-thumbnail-image-darkstudio-x-webflow-template.jpg 1264w"
                                        className="image"/></div>
                                    <h3 className="display-4 mg-bottom-24px title" style={{textAlign: 'left'}}>What did
                                        we learn from
                                        running 100 design sprints this year</h3>
                                </div>
                                <div className="flex-shrink">
                                    <div className="flex-horizontal start">
                                        <div className="text-200 color-neutral-400">Matt Cannon</div>
                                        <div className="divider-vertical small bg-neutral-600"></div>
                                        <div className="text-200 color-neutral-400">Mar 30, 2023</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ArticlesSection;
