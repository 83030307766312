import YVSNFooterLogo from "../assets/custom/YVSN_FULL_COLOR.svg";

const Footer = () => {

    return (
        <footer className="footer-wrapper">
            <div className="footer-top pd-72px">
                <div className="container-default w-container">
                    <div data-w-id="6d43bd31-0d88-d667-04d3-39b3099d9769"
                         className="w-layout-grid grid-footer-2-column---logo-and-form"
                         style={{
                             transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d',
                             opacity: 1
                         }}>
                        <div><a href="/" aria-current="page"
                                className="footer-logo-wrapper mg-bottom-0 w-inline-block w--current"><img
                            src={YVSNFooterLogo}
                            alt="Darkstudio X Webflow Template - Logo"/></a></div>
                        <div id="w-node-_6d43bd31-0d88-d667-04d3-39b3099d976d-099d9766"
                             className="footer-top---links-wrapper">
                            <a data-w-id="6d43bd31-0d88-d667-04d3-39b3099d976e"
                               href="mailto:hello@younivision.com"
                               className="link-wrapper medium text-center w-inline-block">
                                <div className="link-text">hello@younivision.com</div>
                                <div className="line-square-icon link-icon-right"></div>
                            </a>
                            <a data-w-id="6d43bd31-0d88-d667-04d3-39b3099d9773" href="tel:(786)498-3677"
                               className="link-wrapper medium text-center w-inline-block">
                                <div className="link-text">(786) 498 - 3677</div>
                                <div className="line-square-icon link-icon-right"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-middle">
                <div className="container-default w-container">
                    <div className="w-layout-grid grid-footer-3-columns---footer-v1">
                        <div id="w-node-_6d43bd31-0d88-d667-04d3-39b3099d977b-099d9766"
                             data-w-id="6d43bd31-0d88-d667-04d3-39b3099d977b"
                             className="footer-left-content-wrapper"
                             style={{
                                 transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                 transformStyle: 'preserve-3d',
                                 opacity: 1
                             }}>
                            <ul role="list" className="footer-list-wrapper" style={{textAlign: 'left'}}>
                                <li className="footer-list-item"><a
                                    data-w-id="6d43bd31-0d88-d667-04d3-39b3099d9783"
                                    href="/" aria-current="page"
                                    className="footer-link w--current">Home<span
                                    className="line-square-icon link-icon-right medium"></span></a></li>
                                <li className="footer-list-item"><a
                                    data-w-id="6d43bd31-0d88-d667-04d3-39b3099d9788"
                                    href="/about"
                                    className="footer-link">About<span
                                    className="line-square-icon link-icon-right medium"></span></a></li>

                                <li className="footer-list-item"><a
                                    data-w-id="6d43bd31-0d88-d667-04d3-39b3099d97a1"
                                    href="/projects"
                                    className="footer-link">Projects<span
                                    className="line-square-icon link-icon-right medium"></span></a></li>
                                <li className="footer-list-item"><a
                                    data-w-id="6d43bd31-0d88-d667-04d3-39b3099d97a6"
                                    href="https://portal.younivision.com"
                                    className="footer-link">Client Portal<span
                                    className="line-square-icon link-icon-right medium"></span></a></li>
                                <li className="footer-list-item"><a
                                    data-w-id="6d43bd31-0d88-d667-04d3-39b3099d97a6"
                                    href="/forms"
                                    className="footer-link">Forms<span
                                    className="line-square-icon link-icon-right medium"></span></a></li>
                            </ul>
                            <ul role="list" className="footer-list-wrapper" style={{textAlign: 'left'}}>
                                <li className="footer-list-item"><a
                                    data-w-id="6d43bd31-0d88-d667-04d3-39b3099d97ac"
                                    href="/privacy"
                                    className="footer-link">Privacy Policy<span
                                    className="line-square-icon link-icon-right medium"></span></a></li>
                                <li className="footer-list-item"><a
                                    data-w-id="6d43bd31-0d88-d667-04d3-39b3099d97b1"
                                    href="/terms"
                                    className="footer-link">Our Terms<span
                                    className="line-square-icon link-icon-right medium"></span></a></li>
                                <li className="footer-list-item"><a
                                    data-w-id="6d43bd31-0d88-d667-04d3-39b3099d97b6"
                                    href="/contact"
                                    className="footer-link">Contact Us<span
                                    className="line-square-icon link-icon-right medium"></span></a></li>
                            </ul>
                        </div>
                        <div id="w-node-_6d43bd31-0d88-d667-04d3-39b3099d97ce-099d9766"
                             className="divider-vertical _0px horizontal---tablet"></div>
                        <div id="w-node-_6d43bd31-0d88-d667-04d3-39b3099d97cf-099d9766"
                             className="footer-right-content-wrapper">
                            <div>
                                <div className="w-layout-grid grid-1-column gap-row-56px">
                                    <div data-w-id="6d43bd31-0d88-d667-04d3-39b3099d97d2"
                                         className="inner-container _470px _100-tablet"
                                         style={{
                                             transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                             transformStyle: 'preserve-3d',
                                             opacity: 1
                                         }}>
                                        <div className="display-3 mg-bottom-32px" style={{ textAlign: 'left'}}>Subscribe to our Newsletter
                                        </div>
                                        <div className="mg-bottom-0 w-form">
                                            <form id="Footer-Form" name="wf-form-Footer-Subscribe"
                                                  data-name="Footer Subscribe" method="get"
                                                  className="form inside-input"
                                                  data-wf-page-id="6421be6c04e8b3221d76e104"
                                                  data-wf-element-id="6d43bd31-0d88-d667-04d3-39b3099d97d6"
                                                  aria-label="Footer Subscribe">
                                                <div className="position-relative"><input
                                                    className="input button-inside w-input" maxLength="256"
                                                    name="Email" data-name="Email" placeholder="Enter email"
                                                    type="email" id="Footer-Email" required=""/><input
                                                    type="submit" data-wait="Please wait..."
                                                    id="w-node-_6d43bd31-0d88-d667-04d3-39b3099d97d9-099d9766"
                                                    className="btn-secondary inside-input default w-button"
                                                    value="Subscribe"/></div>
                                            </form>
                                            <div className="success-message btn-inside-input w-form-done"
                                                 tabIndex="-1" role="region"
                                                 aria-label="Footer Subscribe success">
                                                <div className="success-message-wrapper inside-input">
                                                    <div className="line-rounded-icon success-message-check">
                                                    </div>
                                                    <div>Thanks for joining our newsletter.</div>
                                                </div>
                                            </div>
                                            <div className="error-message w-form-fail" tabIndex="-1"
                                                 role="region" aria-label="Footer Subscribe failure">
                                                <div>Oops! Something went wrong while submitting the form.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-w-id="6d43bd31-0d88-d667-04d3-39b3099d97e3"
                                         className="image-wrapper footer-v1---image-wrapper"
                                         style={{
                                             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                             transformStyle: 'preserve-3d',
                                             opacity: 1
                                         }}>
                                        <img
                                            src="../assets/642325a1916c1a79d852aaa3_suscribe-to-our-newsletter-image-darkstudio-x-webflow-template.png"
                                            alt="Subscribe to Our Newsletter - Darkstudio X Webflow Template"
                                            sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 704px"
                                            srcSet="https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/642325a1916c1a79d852aaa3_suscribe-to-our-newsletter-image-darkstudio-x-webflow-template-p-500.png 500w, https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/642325a1916c1a79d852aaa3_suscribe-to-our-newsletter-image-darkstudio-x-webflow-template-p-800.png 800w, https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/642325a1916c1a79d852aaa3_suscribe-to-our-newsletter-image-darkstudio-x-webflow-template-p-1080.png 1080w, https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/642325a1916c1a79d852aaa3_suscribe-to-our-newsletter-image-darkstudio-x-webflow-template.png 1408w"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom text-left text-center-mb">
                <div className="container-default w-container">
                    <div data-w-id="6d43bd31-0d88-d667-04d3-39b3099d97e7"
                         className="w-layout-grid grid-footer-paragrah---social-media"
                         style={{
                             transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d',
                             opacity: 1
                         }}>
                        <div className="color-neutral-500">
                            <p className="text-uppercase mg-bottom-0 text-regular">Copyright ©Younivision |
                                Designed by Dahmeyon McDonald
                            </p>
                        </div>
                        <div id="w-node-_6d43bd31-0d88-d667-04d3-39b3099d97ef-099d9766"
                             className="w-layout-grid social-media-grid">
                            <a href="https://facebook.com/" target="_blank"
                               className="social-icon w-inline-block">
                                <div className="social-icon-font"></div>
                            </a>
                            <a href="https://twitter.com/" target="_blank"
                               className="social-icon w-inline-block">
                                <div className="social-icon-font"></div>
                            </a>
                            <a href="https://www.instagram.com/" target="_blank"
                               className="social-icon w-inline-block">
                                <div className="social-icon-font"></div>
                            </a>
                            <a href="https://www.linkedin.com/" target="_blank"
                               className="social-icon w-inline-block">
                                <div className="social-icon-font"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer
