import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SectionOne from "./components/SectionOne";
import SectionTwo from "./components/SectionTwo";
import SectionThree from "./components/SectionThree";
import SectionFour from "./components/SectionFour";
import SectionFive from "./components/SectionFive";
import SectionSx from "./components/SectionSx";

const AboutPage = () => {

    return(
        <body>
            <div className="page-wrapper">
                <Header />
                <SectionOne />
                <SectionTwo />
                <SectionThree />
                <SectionFour />
                <SectionFive />
                {/*<SectionSx />*/}
                <Footer />
            </div>
        </body>
    )

}

export default AboutPage;
