const SectionTwo = () => {

    return (
        <div className="section bottom-0px small">
            <div
                data-w-id="ce8f1b71-831b-e457-3f5f-b7b866a37b82"
                className="cta-section"
                style={{opacity: 1}}
            >
                <div className="container-default w-container">
                    <div
                        data-w-id="ce8f1b71-831b-e457-3f5f-b7b866a37b84"
                        className="inner-container _845px"
                        style={{
                            transform: "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                            transformStyle: "preserve-3d",
                            opacity: 1,
                        }}
                    >
                        <div className="big-font-title display-2---size">Contact</div>
                        <h2 className="display-2 mg-bottom-32px">
                            Have an idea? <br/>
                            Let’s get in touch
                        </h2>
                        <a href="/contact" className="btn-secondary w-button">
                            Contact us
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SectionTwo;
