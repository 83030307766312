import Header from "../../components/Header";
import HeroSection from "./components/HeroSection";
import AboutUsSection from "./components/AboutUsSection";
import ServicesSection from "./components/ServicesSection";
import CTASection from "./components/CTASection";
import PortfolioSection from "./components/PortfolioSection";
import ProcessSection from "./components/ProcessSection";
import ArticlesSection from "./components/ArticlesSection";
import Footer from "../../components/Footer";

const DashboardPage = () => {

    return(
        <>
            <body>
                <div className="page-wrapper">
                    <Header />
                    <HeroSection />
                    <AboutUsSection />
                    <ServicesSection />
                    <CTASection />
                    <PortfolioSection />
                    <ProcessSection />
                    <ArticlesSection />
                    <Footer />
                </div>
            </body>
        </>
    )
}

export default DashboardPage;
