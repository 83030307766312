const SectionTwo = () => {

    return (
        <div className="section small" style={{ textAlign: 'left'}}>
            <div className="container-default w-container">
                <div
                    data-w-id="e359a350-0903-40a8-05c9-2de73ec584f5"
                    style={{
                        transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                        opacity: 1,
                        transformStyle: 'preserve-3d',
                    }}
                    className="title-left---content-right mg-bottom-48px"
                >
                    <div className="inner-container _990px">
                        <div className="big-font-title display-2---size">Visit us</div>
                        <h2 className="display-2 mg-bottom-0">Come and visit one of our offices over the world</h2>
                    </div>
                    <a href="/about#values" className="btn-secondary w-button">
                        Our values
                    </a>
                </div>
                <div className="position-relative">
                    <div data-w-id="629aba00-5067-29ae-9429-08bb0514db44" style={{opacity: 1}}
                         className="image-wrapper position-relative">
                        <div className="bg-overlay bg-neutral-800 opacity-60"></div>
                        <img
                            src="./Contact - Darkstudio X - Webflow Ecommerce website template_files/6424a05067751b1d85433af4_come-and-visit-one-of-our-offices-image-darkstudio-x-webflow-template.jpg"
                            alt="Visit One Of Our Offices - Darkstudio X Webflow Template"
                            sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 1439px) 95vw, 1312px"
                            srcSet="
                https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6424a05067751b1d85433af4_come-and-visit-one-of-our-offices-image-darkstudio-x-webflow-template-p-500.jpg 500w,
                https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6424a05067751b1d85433af4_come-and-visit-one-of-our-offices-image-darkstudio-x-webflow-template-p-800.jpg 800w,
                https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6424a05067751b1d85433af4_come-and-visit-one-of-our-offices-image-darkstudio-x-webflow-template-p-1080.jpg 1080w,
                https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6424a05067751b1d85433af4_come-and-visit-one-of-our-offices-image-darkstudio-x-webflow-template-p-1600.jpg 1600w,
                https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6424a05067751b1d85433af4_come-and-visit-one-of-our-offices-image-darkstudio-x-webflow-template.jpg 2624w
              "
                            className="image visit-us-image"
                        />
                    </div>
                    <div
                        data-w-id="629aba00-5067-29ae-9429-08bb0514db47"
                        style={{
                            transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            opacity: 1,
                            transformStyle: 'preserve-3d',
                        }}
                        className="position-absolute contact-information-wrapper"
                    >
                        <div className="grid-3-columns section-visit-us---about-page">
                            <div id="w-node-_629aba00-5067-29ae-9429-08bb0514db49-3a91415d">
                                <div className="mg-bottom-8px">
                                    <div className="text-300 text-uppercase color-neutral-300">Location:</div>
                                </div>
                                <a
                                    href="https://www.google.com/maps/place/new+york/data=!4m2!3m1!1s0x89c24fa5d33f083b:0xc80b8f06e177fe62?sa=X&amp;ved=2ahUKEwj7_ezcgbf-AhXImmoFHQYkDgEQ8gF6BAh_EAI"
                                    target="_blank"
                                    className="text-link contact-link w-inline-block"
                                    rel="noopener noreferrer"
                                >
                                    <div>New YORK, ny</div>
                                </a>
                            </div>
                            <div id="w-node-_629aba00-5067-29ae-9429-08bb0514db50-3a91415d">
                                <div className="mg-bottom-8px">
                                    <div className="text-300 text-uppercase color-neutral-300">Phone:</div>
                                </div>
                                <a href="tel:(414)248-8027" className="text-link contact-link w-inline-block">
                                    <div>(414) 248 - 8027</div>
                                </a>
                            </div>
                            <div id="w-node-_629aba00-5067-29ae-9429-08bb0514db57-3a91415d">
                                <div className="mg-bottom-8px">
                                    <div className="text-300 text-uppercase color-neutral-300">Email:</div>
                                </div>
                                <a href="mailto:info@darkstudio.com" className="text-link contact-link w-inline-block">
                                    <div>Info@darkstudio.com</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SectionTwo;
