import YVSNHeaderLogo from "../assets/custom/YVSN_EMBLEM_COLOR.svg";

const CenteredHeader = () => {

    return (
        <div className="position-absolute header-wrapper---absolute">
            <div
                data-w-id="455d780e-0dc9-d36d-7e67-07c5b5f20d5f"
                data-animation="default"
                data-collapse="medium"
                data-duration="400"
                data-easing="ease"
                data-easing2="ease"
                role="banner"
                className="header-wrapper w-nav"
                style={{opacity: 1}}
            >
                <div className="container-default w-container">
                    <div className="header-content-wrapper">
                        <div className="header-middle center">
                            <a
                                href="/"
                                className="header-logo-link w-nav-brand"
                            >
                                <img
                                    src={YVSNHeaderLogo}
                                    alt="Darkstudio X Webflow Template - Logo"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0"></div>
            </div>
        </div>
    )

}

export default CenteredHeader;
