
import Icon01 from '../../../assets/6424b55e4b60854ac202bd7c_google-logo-darkstudio-x-webflow-template.svg'
import Icon02 from '../../../assets/6424b560dc3de6450bfa1418_facebook-logo-darkstudio-x-webflow-template.svg'
import Icon03 from '../../../assets/6424b560a2ba880bf028a5e6_youtube-logo-darkstudio-x-webflow-template.svg'
import Icon04 from '../../../assets/6424b55e72a7d995ee7d17fd_pinterest-logo-darkstudio-x-webflow-template.svg'
import Icon05 from '../../../assets/6424b55e90779eaa597944f6_twitch-logo-darkstudio-x-webflow-template.svg'
import Icon06 from '../../../assets/6424b560f953dcb9a87a71fe_discord-logo-darkstudio-x-webflow-template.svg'
import Icon07 from '../../../assets/6424b560a26f8d0a1f15d626_dribbble-logo-darkstudio-x-webflow-template.svg'
import Icon08 from '../../../assets/6424b56064c4b56e394b6c91_webflow-logo-darkstudio-x-webflow-template.svg'
import Icon09 from '../../../assets/6424b55ff4a88ad9c77378e0_behance-logo-darkstudio-x-webflow-template.svg'
import Icon10 from '../../../assets/6424b5605dd0a0412bbfc3c4_product-hunt-logo-darkstudio-x-webflow-template.svg'

const SectionFive = () => {

    const OPTIONS = [
        {title: 'google', icon: Icon01},
        {title: 'facebook', icon: Icon02},
        {title: 'youtube', icon: Icon03},
        {title: 'pinterest', icon: Icon04},
        {title: 'twitch', icon: Icon05},
        {title: 'discord', icon: Icon06},
        {title: 'dribbble', icon: Icon07},
        {title: 'webflow', icon: Icon08},
        {title: 'behance', icon: Icon09},
        {title: 'product-hunt', icon: Icon10}
    ]

    return (
        <div className="section">
            <div className="container-default w-container">
                <div
                    style={{
                        transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)',
                        opacity: 1,
                        transformStyle: 'preserve-3d',
                        textAlign: 'left'
                    }}
                    className="title-left---content-right center-tablet mg-bottom-64px"
                >
                    <div className="inner-container _700px">
                        <div className="big-font-title display-2---size">Clients</div>
                        <h2 className="display-2 mg-bottom-0">Brands we worked with</h2>
                    </div>
                    <div className="inner-container _500px _100-tablet">
                        <p className="mg-bottom-0">
                            We’re proud to have partnered with a range of innovative brands, from startups to established companies, helping them achieve their digital goals.
                        </p>
                    </div>
                </div>
                <div className="w-layout-grid grid-6-columns logo-strip">
                    {OPTIONS.map((logo, index) => (
                        <img
                            key={index}
                            src={logo?.icon ? logo?.icon :  null}
                            alt={`${logo?.title?.toUpperCase()} - YOUNIVISION`}
                            className={`${logo?.title}-logo`}
                        />
                    ))}
                </div>
            </div>
        </div>
    )

}

export default SectionFive;
