const PortfolioSection = () => {

    return (
        <div className="section overflow-hidden">
            <div className="container-default w-container">
                <div data-w-id="a0fc02d6-0127-1bab-0029-7e99b2e43e93"
                     style={{
                         transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         opacity: 1,
                         transformStyle: 'preserve-3d'
                     }}
                     className="title-left---content-right mg-bottom-48px">
                    <div className="inner-container _845px">
                        <div className="big-font-title display-2---size">Projects</div>
                        <h2 className="display-2 mg-bottom-0">Our latest work</h2>
                    </div>
                    <a href="/projects"
                       className="btn-secondary white w-button">Browse all projects</a>
                </div>
                <div data-delay="4000" data-animation="slide" className="slider-wrapper w-slider"
                     data-autoplay="false" data-easing="ease"
                     style={{
                         transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         opacity: 1,
                         transformStyle: 'preserve-3d'
                     }}
                     data-hide-arrows="false" data-disable-swipe="false"
                     data-w-id="3454d939-2586-46ae-9f90-a82b3af7c72f" data-autoplay-limit="0"
                     data-nav-spacing="3" data-duration="500" data-infinite="true" role="region"
                     aria-label="carousel">
                    <div className="slider-mask w-slider-mask" id="w-slider-mask-0">
                        <div className="slide blur-sibling-item w-slide" aria-label="1 of 8" role="group"
                             style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="w-dyn-list">
                                <div role="list" className="w-dyn-items">
                                    <div role="listitem" className="w-dyn-item">
                                        <a href="https://darkstudiotemplate.webflow.io/portfolios/editorial-design-for-haus"
                                           className="portfolio-card w-inline-block">
                                            <div className="image-wrapper mg-bottom-32px"><img
                                                src="../assets/6423642821680f903a1d5ef5_editorial-design-for-haus-thumbnail-image-darkstudio-x-webflow-template.jpg"
                                                alt="Editorial Design for Haus"
                                                sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 650px"
                                                srcSet="https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423642821680f903a1d5ef5_editorial-design-for-haus-thumbnail-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423642821680f903a1d5ef5_editorial-design-for-haus-thumbnail-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423642821680f903a1d5ef5_editorial-design-for-haus-thumbnail-image-darkstudio-x-webflow-template.jpg 1280w"
                                                className="image"/></div>
                                            <div className="portfolio-card-bottom-content">
                                                <div className="inner-container _525px">
                                                    <h3 className="display-3 mg-bottom-0 title">Mobile App
                                                        for IM Academy</h3>
                                                </div>
                                                <div className="card-arrow-wrapper">
                                                    <div className="line-square-icon"></div>
                                                </div>
                                            </div>
                                            <p className="color-neutral-300 mg-bottom-0">Blandit turpis
                                                scelerisque felis nunc odio cras porttitor vitae quis diam
                                                blandit eget purus lorem urna id mattis id ut magnis velit.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide blur-sibling-item w-slide" aria-label="2 of 8" role="group"
                             aria-hidden="true"
                             style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="w-dyn-list" aria-hidden="true">
                                <div role="list" className="w-dyn-items" aria-hidden="true">
                                    <div role="listitem" className="w-dyn-item" aria-hidden="true">
                                        <a href="https://darkstudiotemplate.webflow.io/portfolios/package-design-for-sezane"
                                           className="portfolio-card w-inline-block" tabIndex="-1"
                                           aria-hidden="true">
                                            <div className="image-wrapper mg-bottom-32px" aria-hidden="true">
                                                <img
                                                    src="../assets/64236403e8658c5a5dbd2ae3_package-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template.jpg"
                                                    alt="Package Design for Sezane"
                                                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 650px"
                                                    srcSet="https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64236403e8658c5a5dbd2ae3_package-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64236403e8658c5a5dbd2ae3_package-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64236403e8658c5a5dbd2ae3_package-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template.jpg 1280w"
                                                    className="image" aria-hidden="true"/></div>
                                            <div className="portfolio-card-bottom-content" aria-hidden="true">
                                                <div className="inner-container _525px" aria-hidden="true">
                                                    <h3 className="display-3 mg-bottom-0 title"
                                                        aria-hidden="true">Customer portal for Real Rise</h3>
                                                </div>
                                                <div className="card-arrow-wrapper" aria-hidden="true">
                                                    <div className="line-square-icon" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                            <p className="color-neutral-300 mg-bottom-0" aria-hidden="true">Amet
                                                dui pharetra hac tincidunt mi rhoncus viverra sagittis tincidunt
                                                viverra bibendum non nunc bibendum maecenas nec volutpat
                                                diam.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide blur-sibling-item w-slide" aria-label="3 of 8" role="group"
                             aria-hidden="true"
                             style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="w-dyn-list" aria-hidden="true">
                                <div role="list" className="w-dyn-items" aria-hidden="true">
                                    <div role="listitem" className="w-dyn-item" aria-hidden="true">
                                        <a href="https://darkstudiotemplate.webflow.io/portfolios/billboard-design-for-sezane"
                                           className="portfolio-card w-inline-block" tabIndex="-1"
                                           aria-hidden="true">
                                            <div className="image-wrapper mg-bottom-32px" aria-hidden="true">
                                                <img
                                                    src="../assets/642363c47c68c82b957e2b6d_billboard-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template.jpg"
                                                    alt="Billboard Design for Sezane "
                                                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 650px"
                                                    srcSet="https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/642363c47c68c82b957e2b6d_billboard-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/642363c47c68c82b957e2b6d_billboard-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/642363c47c68c82b957e2b6d_billboard-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template.jpg 1280w"
                                                    className="image" aria-hidden="true"/></div>
                                            <div className="portfolio-card-bottom-content" aria-hidden="true">
                                                <div className="inner-container _525px" aria-hidden="true">
                                                    <h3 className="display-3 mg-bottom-0 title"
                                                        aria-hidden="true">Full Suite for 4you Attorneys</h3>
                                                </div>
                                                <div className="card-arrow-wrapper" aria-hidden="true">
                                                    <div className="line-square-icon" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                            <p className="color-neutral-300 mg-bottom-0"
                                               aria-hidden="true">Viverra non nibh pretium in gravida ornare
                                                nisl quis a eu commodo massa amet enim quam mi commodo orci
                                                vitae enim scelerisque.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide blur-sibling-item w-slide" aria-label="4 of 8" role="group"
                             aria-hidden="true"
                             style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="w-dyn-list" aria-hidden="true">
                                <div role="list" className="w-dyn-items" aria-hidden="true">
                                    <div role="listitem" className="w-dyn-item" aria-hidden="true">
                                        <a href="https://darkstudiotemplate.webflow.io/portfolios/stationary-design-for-globex"
                                           className="portfolio-card w-inline-block" tabIndex="-1"
                                           aria-hidden="true">
                                            <div className="image-wrapper mg-bottom-32px" aria-hidden="true">
                                                <img
                                                    src="../assets/6423614f099312f269d37119_stationary-design-for-globex-thumbnail-image-darkstudio-x-webflow-template.jpg"
                                                    alt="Stationary Design for Globex"
                                                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 650px"
                                                    srcSet="https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423614f099312f269d37119_stationary-design-for-globex-thumbnail-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423614f099312f269d37119_stationary-design-for-globex-thumbnail-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423614f099312f269d37119_stationary-design-for-globex-thumbnail-image-darkstudio-x-webflow-template.jpg 1280w"
                                                    className="image" aria-hidden="true"/></div>
                                            <div className="portfolio-card-bottom-content" aria-hidden="true">
                                                <div className="inner-container _525px" aria-hidden="true">
                                                    <h3 className="display-3 mg-bottom-0 title"
                                                        aria-hidden="true">Full Suite for MyAccidentExperts</h3>
                                                </div>
                                                <div className="card-arrow-wrapper" aria-hidden="true">
                                                    <div className="line-square-icon" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                            <p className="color-neutral-300 mg-bottom-0" aria-hidden="true">Quis
                                                consectetur vestibulum at amet pulvinar lacus sed molestie non
                                                proin ultricies varius aliquam dolor sed consequat odio ut.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide blur-sibling-item w-slide" aria-label="5 of 8" role="group"
                             aria-hidden="true"
                             style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="w-dyn-list" aria-hidden="true">
                                <div role="list" className="w-dyn-items" aria-hidden="true">
                                    <div role="listitem" className="w-dyn-item" aria-hidden="true">
                                        <a href="https://darkstudiotemplate.webflow.io/portfolios/editorial-design-for-haus"
                                           className="portfolio-card w-inline-block" tabIndex="-1"
                                           aria-hidden="true">
                                            <div className="image-wrapper mg-bottom-32px" aria-hidden="true">
                                                <img
                                                    src="../assets/6423642821680f903a1d5ef5_editorial-design-for-haus-thumbnail-image-darkstudio-x-webflow-template.jpg"
                                                    alt="Editorial Design for Haus"
                                                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 650px"
                                                    srcSet="https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423642821680f903a1d5ef5_editorial-design-for-haus-thumbnail-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423642821680f903a1d5ef5_editorial-design-for-haus-thumbnail-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423642821680f903a1d5ef5_editorial-design-for-haus-thumbnail-image-darkstudio-x-webflow-template.jpg 1280w"
                                                    className="image" aria-hidden="true"/></div>
                                            <div className="portfolio-card-bottom-content" aria-hidden="true">
                                                <div className="inner-container _525px" aria-hidden="true">
                                                    <h3 className="display-3 mg-bottom-0 title"
                                                        aria-hidden="true">Editorial Design for Haus</h3>
                                                </div>
                                                <div className="card-arrow-wrapper" aria-hidden="true">
                                                    <div className="line-square-icon" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                            <p className="color-neutral-300 mg-bottom-0"
                                               aria-hidden="true">Blandit turpis scelerisque felis nunc odio
                                                cras porttitor vitae quis diam blandit eget purus lorem urna id
                                                mattis id ut magnis velit.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide blur-sibling-item w-slide" aria-label="6 of 8" role="group"
                             aria-hidden="true"
                             style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="w-dyn-list" aria-hidden="true">
                                <div role="list" className="w-dyn-items" aria-hidden="true">
                                    <div role="listitem" className="w-dyn-item" aria-hidden="true">
                                        <a href="https://darkstudiotemplate.webflow.io/portfolios/package-design-for-sezane"
                                           className="portfolio-card w-inline-block" tabIndex="-1"
                                           aria-hidden="true">
                                            <div className="image-wrapper mg-bottom-32px" aria-hidden="true">
                                                <img
                                                    src="../assets/64236403e8658c5a5dbd2ae3_package-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template.jpg"
                                                    alt="Package Design for Sezane"
                                                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 650px"
                                                    srcSet="https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64236403e8658c5a5dbd2ae3_package-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64236403e8658c5a5dbd2ae3_package-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/64236403e8658c5a5dbd2ae3_package-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template.jpg 1280w"
                                                    className="image" aria-hidden="true"/></div>
                                            <div className="portfolio-card-bottom-content" aria-hidden="true">
                                                <div className="inner-container _525px" aria-hidden="true">
                                                    <h3 className="display-3 mg-bottom-0 title"
                                                        aria-hidden="true">Package Design for Sezane</h3>
                                                </div>
                                                <div className="card-arrow-wrapper" aria-hidden="true">
                                                    <div className="line-square-icon" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                            <p className="color-neutral-300 mg-bottom-0" aria-hidden="true">Amet
                                                dui pharetra hac tincidunt mi rhoncus viverra sagittis tincidunt
                                                viverra bibendum non nunc bibendum maecenas nec volutpat
                                                diam.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide blur-sibling-item w-slide" aria-label="7 of 8" role="group"
                             aria-hidden="true"
                             style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="w-dyn-list" aria-hidden="true">
                                <div role="list" className="w-dyn-items" aria-hidden="true">
                                    <div role="listitem" className="w-dyn-item" aria-hidden="true">
                                        <a href="https://darkstudiotemplate.webflow.io/portfolios/billboard-design-for-sezane"
                                           className="portfolio-card w-inline-block" tabIndex="-1"
                                           aria-hidden="true">
                                            <div className="image-wrapper mg-bottom-32px" aria-hidden="true">
                                                <img
                                                    src="../assets/642363c47c68c82b957e2b6d_billboard-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template.jpg"
                                                    alt="Billboard Design for Sezane "
                                                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 650px"
                                                    srcSet="https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/642363c47c68c82b957e2b6d_billboard-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/642363c47c68c82b957e2b6d_billboard-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/642363c47c68c82b957e2b6d_billboard-design-for-sezane-thumbnail-image-darkstudio-x-webflow-template.jpg 1280w"
                                                    className="image" aria-hidden="true"/></div>
                                            <div className="portfolio-card-bottom-content" aria-hidden="true">
                                                <div className="inner-container _525px" aria-hidden="true">
                                                    <h3 className="display-3 mg-bottom-0 title"
                                                        aria-hidden="true">Billboard Design for Sezane </h3>
                                                </div>
                                                <div className="card-arrow-wrapper" aria-hidden="true">
                                                    <div className="line-square-icon" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                            <p className="color-neutral-300 mg-bottom-0"
                                               aria-hidden="true">Viverra non nibh pretium in gravida ornare
                                                nisl quis a eu commodo massa amet enim quam mi commodo orci
                                                vitae enim scelerisque.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide blur-sibling-item w-slide" aria-label="8 of 8" role="group"
                             aria-hidden="true"
                             style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="w-dyn-list" aria-hidden="true">
                                <div role="list" className="w-dyn-items" aria-hidden="true">
                                    <div role="listitem" className="w-dyn-item" aria-hidden="true">
                                        <a href="https://darkstudiotemplate.webflow.io/portfolios/stationary-design-for-globex"
                                           className="portfolio-card w-inline-block" tabIndex="-1"
                                           aria-hidden="true">
                                            <div className="image-wrapper mg-bottom-32px" aria-hidden="true">
                                                <img
                                                    src="../assets/6423614f099312f269d37119_stationary-design-for-globex-thumbnail-image-darkstudio-x-webflow-template.jpg"
                                                    alt="Stationary Design for Globex"
                                                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 650px"
                                                    srcSet="https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423614f099312f269d37119_stationary-design-for-globex-thumbnail-image-darkstudio-x-webflow-template-p-500.jpg 500w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423614f099312f269d37119_stationary-design-for-globex-thumbnail-image-darkstudio-x-webflow-template-p-800.jpg 800w, https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6423614f099312f269d37119_stationary-design-for-globex-thumbnail-image-darkstudio-x-webflow-template.jpg 1280w"
                                                    className="image" aria-hidden="true"/></div>
                                            <div className="portfolio-card-bottom-content" aria-hidden="true">
                                                <div className="inner-container _525px" aria-hidden="true">
                                                    <h3 className="display-3 mg-bottom-0 title"
                                                        aria-hidden="true">Stationary Design for Globex</h3>
                                                </div>
                                                <div className="card-arrow-wrapper" aria-hidden="true">
                                                    <div className="line-square-icon" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                            <p className="color-neutral-300 mg-bottom-0" aria-hidden="true">Quis
                                                consectetur vestibulum at amet pulvinar lacus sed molestie non
                                                proin ultricies varius aliquam dolor sed consequat odio ut.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div aria-live="off" aria-atomic="true" className="w-slider-aria-label"
                             data-wf-ignore=""></div>
                    </div>
                    <div className="slider-divider"></div>
                    <div className="slider-arrow left w-slider-arrow-left" role="button" tabIndex="0"
                         aria-controls="w-slider-mask-0" aria-label="previous slide">
                        <div className="line-square-icon"></div>
                    </div>
                    <div className="slider-arrow right w-slider-arrow-right" role="button" tabIndex="0"
                         aria-controls="w-slider-mask-0" aria-label="next slide">
                        <div className="line-square-icon"></div>
                    </div>
                    <div className="hidden-on-desktop w-slider-nav w-round w-num">
                        <div className="w-slider-dot w-active" data-wf-ignore="" aria-label="Show slide 1 of 8"
                             aria-pressed="true" role="button" tabIndex="0"
                             style={{marginLeft: '3px', marginRight: '3px'}}>1
                        </div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 2 of 8"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}>2
                        </div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 3 of 8"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}>3
                        </div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 4 of 8"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}>4
                        </div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 5 of 8"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}>5
                        </div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 6 of 8"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}>6
                        </div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 7 of 8"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}>7
                        </div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 8 of 8"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}>8
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PortfolioSection;
