const SectionOne = () => {

    return (
        <div className="section small top">
            <div className="container-default w-container">
                <div
                    data-w-id="3156f34e-2be8-e330-1212-2909eb503a0e"
                    style={{
                        transform: "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                    }}
                    className="inner-container _990px center"
                >
                    <div className="text-center mg-bottom-100px">
                        <div className="big-font-title">Pricing</div>
                        <h1 className="display-1">Development Pricing</h1>
                        <div className="inner-container _745px center">
                            <p className="mg-bottom-0">Lorem ipsum dolor sit amet consectetur. Id integer ipsum
                                tristique tellus adipiscing ornare eget eu urna curabitur feugiat viverra feugi.</p>
                        </div>
                    </div>
                </div>
                <div
                    data-w-id="3156f34e-2be8-e330-1212-2909eb503a17"
                    style={{
                        transform: "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                    }}
                    className="w-dyn-list"
                >
                    <div role="list" className="grid-1-column products-grid w-dyn-items">
                        <div role="listitem" className="product-card-item-wrapper w-dyn-item">
                            <div id="w-node-_3156f34e-2be8-e330-1212-2909eb503a1a-726c73a0"
                                 className="image-wrapper product-card-image">
                                <img
                                    alt="Stationary brand Design"
                                    src="./Packages - Darkstudio X - Webflow Ecommerce website template_files/6427663b0cb3c87a3677eaab_stationary-brand-design-image-darkstudio-x-webflow-template.jpg"
                                    data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, (max-width: 1439px) 48vw, 656px"
                                    srcSet="
                                https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6427663b0cb3c87a3677eaab_stationary-brand-design-image-darkstudio-x-webflow-template-p-500.jpg  500w,
                                https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6427663b0cb3c87a3677eaab_stationary-brand-design-image-darkstudio-x-webflow-template-p-800.jpg  800w,
                                https://cdn.prod.website-files.com/6421b2d2f79c9c077b222ffc/6427663b0cb3c87a3677eaab_stationary-brand-design-image-darkstudio-x-webflow-template.jpg       1308w
                            "
                                    className="image"
                                />
                            </div>
                            <div id="w-node-_3156f34e-2be8-e330-1212-2909eb503a1c-726c73a0"
                                 className="product-card-content">
                                <div className="inner-container _446px _100-tablet">
                                    <a data-w-id="3156f34e-2be8-e330-1212-2909eb503a1e"
                                       href="/contact"
                                       className="text-decoration-none w-inline-block">
                                        <h2 className="display-3 mg-bottom-12px title">Custom Mobile/Web Development</h2>
                                        <p className="mg-bottom-0 color-neutral-400">
                                            Whether you’re looking to launch a new product or improve an existing service, we provide custom development that aligns with your goals and vision.
                                        </p>
                                        <div className="divider _32px"></div>
                                        <div className="flex-horizontal space-between">
                                            <div
                                                data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D"
                                                className="display-4">Contact Us
                                            </div>
                                            <div className="card-arrow-wrapper">
                                                <div className="line-square-icon"></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Repeat similar structure for other product items */}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SectionOne;
