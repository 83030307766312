const ProcessSection = () => {

    return (
        <div className="section overflow-hidden">
            <div className="container-default w-container">
                <div data-w-id="575a668b-3857-d801-0bfb-e2b26f943f92"
                     style={{
                         transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         opacity: 1,
                         transformStyle: 'preserve-3d'
                     }}
                     className="inner-container _1068px center">
                    <div className="text-center">
                        <div className="big-font-title display-2---size">Process</div>
                        <h2 className="display-2 mg-bottom-64px">A simple, yet effective three step process</h2>
                    </div>
                </div>
            </div>
            <div className="position-relative process-cards-wrapper">
                <div className="container-default w-container">
                    <div className="w-layout-grid grid-3-columns process-section---home-page">
                        <div id="w-node-_0279bc73-fe1f-45a1-0fc6-2e2dbca0c849-1d76e104"
                             data-w-id="0279bc73-fe1f-45a1-0fc6-2e2dbca0c849" style={{opacity: 1}}
                             className="process-card-wrapper">
                            <div>
                                <div className="display-2 mg-bottom-12px">01</div>
                                <h3 className="display-4 mg-bottom-12px">Discover & Plan</h3>
                                <p className="mg-bottom-0px---tablet">
                                    We begin by understanding your goals, audience, and vision to create a strategic roadmap tailored to your needs.
                                </p>
                            </div>
                            <div className="procces-card-circle current"></div>
                        </div>
                        <div id="w-node-d3a02ec4-8412-aa90-5d6b-cff0705bafd0-1d76e104"
                             data-w-id="d3a02ec4-8412-aa90-5d6b-cff0705bafd0" className="process-card-wrapper"
                             style={{opacity: 1}}>
                            <div>
                                <div className="display-2 mg-bottom-12px">02</div>
                                <h3 className="display-4 mg-bottom-12px">Design & Develop</h3>
                                <p className="mg-bottom-0px---tablet">
                                    Our team crafts intuitive designs and develops high-performance solutions, ensuring seamless functionality and engaging user experiences.
                                </p>
                            </div>
                            <div className="procces-card-circle"></div>
                        </div>
                        <div id="w-node-_1bad847d-f794-016f-7ad8-732fb6cea000-1d76e104"
                             data-w-id="1bad847d-f794-016f-7ad8-732fb6cea000" className="process-card-wrapper"
                             style={{opacity: 1}}>
                            <div>
                                <div className="display-2 mg-bottom-12px">03</div>
                                <h3 className="display-4 mg-bottom-12px">Launch & Support</h3>
                                <p className="mg-bottom-0px---tablet">
                                    We launch with precision and provide ongoing support, continuously optimizing for performance and growth as your needs evolve.
                                </p>
                            </div>
                            <div className="procces-card-circle"></div>
                        </div>
                    </div>
                    <div data-w-id="2e8840cd-c362-5333-5f69-b32c1fbb0aff" style={{opacity: 1}}
                         className="process-section-divider"></div>
                </div>
            </div>
            <div className="container-default w-container">
                <div data-w-id="4691d451-9585-c70c-86cc-ddd3b14c5246"
                     style={{
                         transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         opacity: 1,
                         transformStyle: 'preserve-3d'
                     }}
                     className="buttons-row center"><a href="/contact"
                                                       className="btn-secondary w-button">Contact us</a></div>
            </div>
        </div>
    )

}

export default ProcessSection
