import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBvY-1moFTA6yeTF5Ko8z52o9AySWN8ssY",
    authDomain: "younivision-landing.firebaseapp.com",
    projectId: "younivision-landing",
    storageBucket: "younivision-landing.firebasestorage.app",
    messagingSenderId: "336989123258",
    appId: "1:336989123258:web:36983805eb957cc1fb4883"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);

export { firestore };
