import Image01 from "../../../assets/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template.jpg";

const NotFoundSection = () => {

    return (
        <div className="section section-hero---coming-soon-page">
            <div className="container-default w-container">
                <div className="w-layout-grid grid-2-columns coming-soon-grid">
                    <div style={{ zIndex: 2, textAlign: 'left'}}
                         id="w-node-_09359ef1-59e8-4102-bb01-564c2e2a4195-7b4d5e6e"
                         className="position-relative z-index-1"
                    >
                        <div
                            data-w-id="16d45e23-9ccd-1070-1237-5f79220cfbec"
                            style={{
                                transform:
                                    "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="inner-container _770px _100-tablet"
                        >
                            <h1 className="display-1">NOT FOUND</h1>
                            <div className="inner-container _745px _100-tablet">
                                <p className="mg-bottom-56px">
                                    It looks like the page you're looking for doesn't exist. If you believe this is an error please contact hello@younivision.com
                                </p>
                            </div>
                            {/*<div className="inner-container _600px _100-tablet">*/}
                            {/*    <div className="mg-bottom-0 w-form">*/}
                            {/*        <form*/}
                            {/*            id="wf-form-Coming-Soon-Form"*/}
                            {/*            name="wf-form-Coming-Soon-Form"*/}
                            {/*            data-name="Coming Soon Form"*/}
                            {/*            method="get"*/}
                            {/*            className="form inside-input"*/}
                            {/*            data-wf-page-id="642776536a10ec137b4d5e6e"*/}
                            {/*            data-wf-element-id="57eb187c-2e12-ea40-a8a6-36c6ad09d619"*/}
                            {/*            aria-label="Coming Soon Form"*/}
                            {/*        >*/}
                            {/*            <div className="position-relative">*/}
                            {/*                <input*/}
                            {/*                    className="input button-inside w-input"*/}
                            {/*                    maxLength="256"*/}
                            {/*                    name="Email"*/}
                            {/*                    data-name="Email"*/}
                            {/*                    placeholder="Enter email"*/}
                            {/*                    type="email"*/}
                            {/*                    id="Email"*/}
                            {/*                    required*/}
                            {/*                />*/}
                            {/*                <input*/}
                            {/*                    type="submit"*/}
                            {/*                    data-wait="Please wait..."*/}
                            {/*                    id="w-node-_57eb187c-2e12-ea40-a8a6-36c6ad09d61c-7b4d5e6e"*/}
                            {/*                    className="btn-secondary inside-input default w-button"*/}
                            {/*                    value="Subscribe"*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*        </form>*/}
                            {/*        <div*/}
                            {/*            className="success-message btn-inside-input w-form-done"*/}
                            {/*            tabIndex="-1"*/}
                            {/*            role="region"*/}
                            {/*            aria-label="Coming Soon Form success"*/}
                            {/*        >*/}
                            {/*            <div className="success-message-wrapper inside-input">*/}
                            {/*                <div className="line-rounded-icon success-message-check"></div>*/}
                            {/*                <div>Thanks for joining our newsletter.</div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div*/}
                            {/*            className="error-message w-form-fail"*/}
                            {/*            tabIndex="-1"*/}
                            {/*            role="region"*/}
                            {/*            aria-label="Coming Soon Form failure"*/}
                            {/*        >*/}
                            {/*            <div>Oops! Something went wrong while submitting the form.</div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div
                        data-w-id="352ed507-6e21-a732-d1ce-47d6a55e3ef5"
                        style={{opacity: 1}}
                        className="image-wrapper coming-soon-image"
                    >
                        <div className="opacity-20---tablet">
                            <img
                                src={Image01}
                                alt="Coming Soon - Darkstudio X Webflow Template"
                                sizes="(max-width: 1439px) 100vw, 1440px"
                                srcSet="
                            https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template-p-500.jpg   500w,
                            https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template-p-800.jpg   800w,
                            https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template-p-1080.jpg 1080w,
                            https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template-p-1600.jpg 1600w,
                            https://assets-global.website-files.com/6421b2d2f79c9c30ee222ffd/6428f80ab9ddc8371b30b42a_coming-soon-image-darkstudio-x-webflow-template.jpg        2880w
                        "
                                className="image coming-soon"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFoundSection
