import {Outlet} from "react-router-dom";

const ProjectsLayout = ({ children }) => {

    return(
        <div>
            <main>
                <Outlet />
            </main>
        </div>
    )

}

export default ProjectsLayout
