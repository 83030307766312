const FAQItem = ({ title, content, expandedIndices = [], index = 0, handleIndexToggle }) => {

    return (
        <div className="accordion-item-wrapper v2">
            <div className="container-default small width-100 w-container">
                <div className="flex-horizontal space-between align-top">
                    <div className="accordion-content-wrapper v2" onClick={() => handleIndexToggle(index)}>
                        <div className="accordion-header">
                            <h3 className="accordion-title display-4 title" style={{color: '#ffffff'}}>{title}</h3>
                        </div>
                        <div
                            style={{
                                opacity: expandedIndices?.includes(index) ? 1 : 0,
                                transform: 'translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1)',
                                transformStyle: 'preserve-3d',
                                width: '705px',
                                height: expandedIndices?.includes(index) ? 'auto' : '0px',
                            }}
                            className="accordion-body"
                        >
                            <div className="accordion-spacer"></div>
                            <p className="mg-bottom-0">{content}</p>
                        </div>
                    </div>
                    <div className="accordion-side right-side">
                        <div className="accordion-icon-wrapper">
                            <div
                                className="accordion-btn-line vertical"
                                style={{
                                    transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1)',
                                    transformStyle: 'preserve-3d'
                                }}
                            ></div>
                            <div
                                className="accordion-btn-line horizontal"
                                style={{
                                    transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1)',
                                    transformStyle: 'preserve-3d'
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQItem;
